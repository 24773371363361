import { useEffect, useState } from 'react';
import add from '../../../assets/add.svg';
import { useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { Conversations } from '../../../redux/features/conversations/ConversationsState';
import { isToday } from '../../utils/TextUtils';
import { getFriendlyChatName, useChat } from '../../../libs/hooks/useChat';
import { ChatListSection } from './ChatListSection';

interface ConversationsView {
    latestConversations?: Conversations;
    olderConversations?: Conversations;
}

function ChatList({ selectedModel }: any) {
    const chat = useChat();

    const { conversations } = useAppSelector((state: RootState) => state.conversations);

    const [filterText, setFilterText] = useState('');
    const [conversationsView, setConversationsView] = useState<ConversationsView>({
        latestConversations: conversations,
    });
    const [isCreatingNewChat, setisCreatingNewChat] = useState(false);

    const sortConversations = (conversations: Conversations): ConversationsView => {
        const sortedIds = Object.keys(conversations).sort((a, b) => {
            if (conversations[a].lastUpdatedTimestamp === undefined) {
                return 1;
            }
            if (conversations[b].lastUpdatedTimestamp === undefined) {
                return -1;
            }

            return conversations[a].lastUpdatedTimestamp - conversations[b].lastUpdatedTimestamp;
        });

        // Add conversations to sortedConversations in the order of sortedIds.
        const latestConversations: Conversations = {};
        const olderConversations: Conversations = {};
        sortedIds.forEach((id) => {
            if (isToday(new Date(conversations[id].lastUpdatedTimestamp ?? 0))) {
                latestConversations[id] = conversations[id];
            } else {
                olderConversations[id] = conversations[id];
            }
        });
        return {
            latestConversations: latestConversations,
            olderConversations: olderConversations,
        };
    };

    useEffect(() => {
        // Ensure local component state is in line with app state.
        const nonHiddenConversations: Conversations = {};
        for (const key in conversations) {
            const conversation = conversations[key];
            if (
                !conversation.hidden &&
                (!filterText ||
                    getFriendlyChatName(conversation).toLocaleUpperCase().includes(filterText.toLocaleUpperCase()))
            ) {
                nonHiddenConversations[key] = conversation;
            }
        }

        setConversationsView(sortConversations(nonHiddenConversations));
    }, [conversations, filterText]);

    const onAddChat = () => {
        setisCreatingNewChat(true);
        void chat.createChat();
        setTimeout(() => {
            setisCreatingNewChat(false);
        }, 1500);
    };

    return (
        <div
            className="w-[380px] bg-[#FEFEFF] 2xl:w-[380px] xl:w-[350px] lg:w-[320px] md:w-[300px]"
            style={{ boxShadow: '0px 17px 40px 4px #7090B00F' }}
        >
            <div className="py-5 flex flex-col h-full justify-start">
                <div className="flex gap-1 items-center justify-start px-4">
                    <i className="icon icon-unicon"></i>
                    <span className="text-2xl text-unicon-lightBlue font-medium">
                        Chat<span className="font-bold">Bolt</span>
                    </span>
                </div>

                <div
                    className={`flex items-center gap-2.5 bg-[#F4F7FE] h-[2.688rem] rounded-lg mt-10 pl-2.5 mx-5 cursor-pointer w-[8.625rem] ${isCreatingNewChat && 'opacity-50 cursor-not-allowed'}`}
                    onClick={onAddChat}
                >
                    <img src={add} alt="add" width={24} height={24} />
                    <h3 className="text-base font-semibold text-unicon-lightBlue">New Chat</h3>
                </div>
                <h3 className="text-[#718096;] font-semibold p-2 mt-4 text-base leading-4 px-5">Recent</h3>

                <div aria-label={'chat list'} className="flex grow mb-2">
                    {conversationsView.latestConversations && (
                        <ChatListSection conversations={conversationsView.latestConversations} />
                    )}
                    {conversationsView.olderConversations && (
                        <ChatListSection conversations={conversationsView.olderConversations} />
                    )}
                </div>

                {/* <div className="flex justify-center">
                    <h3 className="text-[#1D24CA] font-medium text-sm">See all</h3>
                </div> */}
            </div>
        </div>
    );
}

export default ChatList;
