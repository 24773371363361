import React from 'react';
import Sidebar from './Sidebar';
import AdminTopHeader from'./AdminTopHeader';

const withLayout = <P extends object>(WrappedComponent: React.ComponentType<P>): React.FC<P> => {
    const ComponentWithLayout: React.FC<P> = (props: P) => (
        <div style={{ display: 'flex' }}>
            <Sidebar />
            <div className="w-full ">
                <AdminTopHeader />
                <main style={{ flex: '1', padding: '12px 30px 12px 30px' }} className="bg-unicon-white">
                    <WrappedComponent {...props} />
                </main>
            </div>
        </div>
    );

    
    ComponentWithLayout.displayName = `withLayout('Component')`;

    return ComponentWithLayout;
};

export default withLayout;
