import React from 'react';
import { AuthHelper } from '../../libs/auth/AuthHelper';
import { AppState } from '../../App';
import { BackendProbe, Error, Loading } from '../views';
import ChatView from '../views/ChatView';

const Chat = ({ appState, setAppState }: { appState: AppState; setAppState: (state: AppState) => void }) => {
    const onBackendFound = React.useCallback(() => {
        setAppState(AuthHelper.isAuthAAD() ? AppState.SettingUserInfo : AppState.LoadingChats);
    }, [setAppState]);

    return (
        <div className="unicon_container relative ">
            {appState === AppState.ProbeForBackend && <BackendProbe onBackendFound={onBackendFound} />}
            {appState === AppState.SettingUserInfo && (
                <Loading text={'Hang tight while we fetch your information...'} />
            )}
            {appState === AppState.ErrorLoadingUserInfo && (
                <Error text={'Unable to load user info. Please try signing out and signing back in.'} />
            )}
            {appState === AppState.ErrorLoadingChats && (
                <Error text={'Unable to load chats. Please try refreshing the page.'} />
            )}
            {appState === AppState.LoadingChats && <Loading text="Loading chats..." />}
            {appState === AppState.Chat && <ChatView />}
        </div>
    );
};

export default Chat;
