import React from 'react';

interface ProgressBarProps {
    value: number;
    max: number;
    
}

const ProgressBarModal: React.FC<ProgressBarProps> = ({ value, max }) => {
    const percentage = (value / max) * 100;

    return (
        <div className="flex flex-col">
            <span className='text-base font-medium text-unicon-gray text-right mb-[2px]'>56% <span className='text-sm'>Used</span></span>
            <div style={{ width: '100%' }}>
                <div
                    style={{
                        position: 'relative',
                        height: '8px',
                        borderRadius: '4px',
                        backgroundColor: '#E7EAF1',
                    }}
                >
                    <div
                        style={{
                            width: `${percentage}%`,
                            height: '100%',
                            borderRadius: '4px',
                            backgroundColor: '#FBA834',
                            transition: 'width 0.3s ease-in-out',
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default ProgressBarModal;
