import microsoftIcon from '../../assets/microsoft.svg';
import downArrow from '../../assets/down_arrow.svg';
import notification from '../../assets/notification.svg';
import theme from '../../assets/theme.svg';
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { getInitials } from '../utils/Helper';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { setSelectedModel } from '../../redux/features/models/modelsSlice';

const ChatHeader: any = () => {
    const location = useLocation();
    const { activeUserInfo } = useAppSelector((state: RootState) => state.app);
    const { selectedModel, modelsList } = useAppSelector((state: RootState) => state.models);

    const navigate = useNavigate();

    const [showMenu, setshowMenu] = useState(false);
    const headerMenu = ['dashboard', 'settings', 'logout'];

    const transformString = (str: string) => {
        return str.replace(/\//g, '').replace(/-/g, ' ');
    };
    const [isDropdownChatModel, setIsDropdownChatModel] = useState(false);

    const toggleDropdownChatModel = () => {
        setIsDropdownChatModel(!isDropdownChatModel);
    };

    const dispatch = useAppDispatch();

    const handleOptionSelect = (option: any): void => {
        dispatch(setSelectedModel(option));
        setIsDropdownChatModel(false);
    };
    console.log(selectedModel)
    const selectedModelName = selectedModel?.modelName ? `${selectedModel.modelName}-${selectedModel.poolName}` : 'Loading...';

    const getDynamicHeading = () => {
        if (location.pathname === '/') {
            return (
                <div className="relative">
                    <div
                        className="h-[44px] px-3 rounded-[8px] flex items-center justify-between  border border-unicon-modalBorder cursor-pointer w-[270px]"
                        style={{ boxShadow: '0px 1px 2px 0px #1018280D' }}
                        onClick={toggleDropdownChatModel}
                    >
                        <span className="inline-flex items-center justify-start">
                            <img src={microsoftIcon} alt="Microsoft icon" width={22} height={22} />
                            <h3 className="text-xl leading-5 font-medium text-unicon-lightBlue pl-2 text-ellipsis overflow-hidden whitespace-nowrap max-w-[195px]">
                                {selectedModelName}
                            </h3>
                        </span>
                        <span className="w-[30px] h-[30px] flex items-center justify-end">
                            <img src={downArrow} alt="Dropdown arrow" width={28} height={28} />
                        </span>
                    </div>

                    {isDropdownChatModel && (
                        <div className="mt-2 bg-white rounded-[8px] shadow-lg absolute top-[40px] left-0 min-w-[170px] max-w-[300px] z-10">
                            <ul className="py-2 w-full">
                                {modelsList.map((model: any) => {
                                    return (
                                        <li
                                            onClick={() => {
                                                handleOptionSelect(model);
                                            }}
                                            key={model.modelId}
                                            className="px-4 py-2 hover:bg-unicon-chatLiHover cursor-pointer w-full inline-flex items-center justify-start"
                                        >
                                            <img src={microsoftIcon} alt="Microsoft icon" width={22} height={22} />
                                            <span className="text-xl leading-5 font-medium text-unicon-lightBlue pl-2">
                                                {model.modelName}-{model.poolName}
                                            </span>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    )}
                </div>
            );
        }

        return (
            <div className="h-[44px] py-2 px-4 rounded-[8px] flex gap-4">
                <h3 className="text-xl font-semibold  text-unicon-lightBlue capitalize">
                    {transformString(location.pathname)}
                </h3>
            </div>
        );
    };

    return (
        <div className="px-4 py-5 flex justify-between ">
            {getDynamicHeading()}
            <div
                className="h-[61px] w-[150px]  px-6 rounded-[30px] flex items-center justify-end gap-2  bg-white border border-[#E9EDF7]"
                style={{ boxShadow: '14px 17px 40px 4px #7090B014' }}
            >
                <img src={notification} alt="microsoft icon" width={24} height={24} />
                <img src={theme} alt="microsoft icon" width={24} height={24} />
                <div className="relative">
                    <div
                        className="flex items-center justify-center w-[42px] h-[42px]  rounded-full bg-unicon-gray text-white text-xl font-semibold cursor-pointer"
                        onClick={() => {
                            setshowMenu((showMenu) => !showMenu);
                        }}
                    >
                        {getInitials(activeUserInfo?.username)}
                    </div>
                    {showMenu && (
                        <div className="absolute right-0 rounded-xl shadow bg-white px-4 py-2 border w-40 z-50">
                            {headerMenu.map((item: string, index: number) => {
                                return (
                                    <div
                                        key={index}
                                        className="text-base font-semibold my-4 capitalize cursor-pointer"
                                        onClick={() => {
                                            navigate(`/${item}`);
                                        }}
                                    >
                                        {item}
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ChatHeader;
