import React, { useState, useEffect } from 'react';
import withLayout from '../dashboard/WithLayout';
import { Table, Header, HeaderRow, Body, Row, HeaderCell, Cell } from '@table-library/react-table-library/table';
import { useTheme } from '@table-library/react-table-library/theme';
import ProgressBar from '../utils/ProgressBar';
import Modal from '../utils/Modal';
import Microsoft from '../../assets/microsoft.svg';
import { ModelService } from '../../libs/services/ModelService';
import { useMsal } from '@azure/msal-react';
import { AuthHelper } from '../../libs/auth/AuthHelper';
import LoadingSpinner from '../../hooks/Loader';

interface Node {
    id: string;
    name: string;
    provider: string;
    version: number;
    type: string;
    added_on: string;
    pool: number;
    amounts: number;
    token_limit: number;
    token_usage: number;
    pool_name: string;
    no_of_users: number;
    Token_Ent_yearly: number;
    Token_Ent_month: number;
}
interface Data {
    nodes: Node[];
}

const nodes: Node[] = [
    {
        id: '1',
        name: 'Model 1',
        provider: 'Microsoft',
        version: 3.5,
        type: 'Chat completion',
        added_on: 'Feb, 2024',
        pool: 2,
        amounts: 10000,
        token_limit: 10000,
        token_usage: 3.6,
        pool_name: 'Pool 1',
        no_of_users: 6,
        Token_Ent_yearly: 10000,
        Token_Ent_month: 833,
    },
    {
        id: '1',
        name: 'Model 1',
        provider: 'Microsoft',
        version: 3.5,
        type: 'Chat completion',
        added_on: 'Feb, 2024',
        pool: 2,
        amounts: 10000,
        token_limit: 10000,
        token_usage: 3.6,
        pool_name: 'Pool 2',
        no_of_users: 13,
        Token_Ent_yearly: 10000,
        Token_Ent_month: 833,
    },
    {
        id: '1',
        name: 'Model 1',
        provider: 'Microsoft',
        version: 3.5,
        type: 'Chat completion',
        added_on: 'Feb, 2024',
        pool: 2,
        amounts: 10000,
        token_limit: 10000,
        token_usage: 3.6,
        pool_name: 'Pool 3',
        no_of_users: 12,
        Token_Ent_yearly: 10000,
        Token_Ent_month: 833,
    },
];

function ModelsTable() {
    const { instance, inProgress } = useMsal();
    const modelService = new ModelService();
    const data: Data = { nodes };
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState<string>('Oct');
    const [isOpen, setIsOpen] = useState(false);
    const [modelsList, setmodelsList] = React.useState<any>([]);

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionSelect = (option: string): void => {
        setSelectedOption(option);
        setIsOpen(false);
        return;
    };

    const [filterItems, setFilterItems] = useState([
        { id: 1, label: 'model 3' },
        { id: 2, label: 'model 4' },
    ]);
    const handleRemoveItem = (id: number) => {
        setFilterItems(filterItems.filter((item) => item.id !== id));
    };

    const theme = useTheme({
        HeaderRow: `
        .th {
         border-bottom:1px solid #EAECF0;
         padding:12px 24px;
         background-color: #F6F7FA;
         height:44px;
        }
      `,
        BaseCell: `
       &:first-of-type {
       >*{display:flex;}
       }
       
        &.icon-cell >div{
        display:inline-flex;
        align-item:center;
        justify-content:end;
       }
       border-bottom:1px solid #EAECF0;
       padding:12px 24px;
       height:60px;
      `,
    });

    const getModelsList = async () => {
        modelService.getModelsAsync(await AuthHelper.getSKaaSAccessToken(instance, inProgress)).then((response) => {
            setmodelsList(response);
        });
    };

    useEffect(() => {
        getModelsList();
    }, []);

    const [modelsDataForTable, setmodelsDataForTable] = useState<any>([]);

    useEffect(() => {
        const tempData: any = { nodes: [] };
        modelsList.forEach((model) => {
            const date = new Date(model.createdOn);
            const formattedDate = date.toLocaleString('en-US', {
                month: 'short',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: true,
            });
            tempData.nodes.push({
                id: model.id,
                name: model.name,
                provider: model.provider,
                version: model.version,
                type: model.type,
                added_on: formattedDate,
                pool: model.poolModelEntitlements.length,
                amounts: null,
                token_limit: model.maxTokens,
                token_usage: null,
                pool_name: null,
                no_of_users: null,
                Token_Ent_yearly: null,
                Token_Ent_month: null,
            });
        });
        setmodelsDataForTable(tempData);
    }, [modelsList]);


    return (
        <div>
            <div className="flex justify-between items-center h-[60px]">
                <div className='inputwrap h-10 flex items-center justify-start relative'>
                    <i className='icon icon-input-search absolute left-0 cursor-pointer'></i>
                <input className="min-w-[332px] h-[32px] border-b border-[#D0D5DD] border-solid focus-visible:outline-none pl-[26px] text-sm text-unicon-lightBlue font-medium" type="text" placeholder='Search users/roles..' />
                <button className={`w-10 h-10 flex items-center justify-center relative  ${filterItems.length >= 1 ? 'filter-active-icon' : ''} `} style={{transition:'all 0.3s ease'}}>
                    <i className='icon icon-filter'></i>
                </button>
                </div>
                    <div className='h-10 flex items-center justify-end'>
                            <div className='inline-flex items-center justify-center border border-solid border-unicon-grayBorder rounded-md relative'>
                                <button className='font-medium text-base text-unicon-lightBlue  px-[10px] bg-unicon-white h-10 '>Yearly</button>
                                <button className=' px-[10px] flex items-center justify-between bg-unicon-white dropdown-button active-toggle h-10 ' onClick={toggleDropdown}>
                                    <span className='font-medium text-base text-unicon-lightBlue leading-4  w-[36px] '> {selectedOption}</span><i className='icon icon-arrowdown'></i>
                                </button>
                            </div>
                            <button className="w-11 h-6 flex items-center justify-end "><i className="icon icon-excel"></i></button>
                    </div>
            </div>
            <div
                className="border border-solid border-unicon-grayBorder rounded h-[calc(100vh-214px)]"
                style={{ boxShadow: '0px 1px 2px 0px #1018280F, 0px 1px 3px 0px #1018281A' }}
            >
                <div
                    className={`filter-list-wrpa flex justify-start py-3 px-6 min-h-[56px] ${filterItems.length === 0 ? 'hidden' : ''}`}
                    style={{ transition: 'all 0.3s ease' }}
                >
                    <span className="font-semibold text-base text-unicon-gray w-[65px] m-auto">Filters :</span>
                    <ul className="scroll-div inline-flex justify-start overflow-x-hidden overflow-y-auto gap-3 max-h-[100px] flex-wrap w-[calc(100%-65px)]">
                        {filterItems.map((item) => (
                            <li
                                key={item.id}
                                className="flex items-center justify-between h-7 rounded-2xl gap-[0.5rem] py-4 pl-3 pr-2 bg-unicon-chatLiHover"
                            >
                                <span className="font-medium text-base text-unicon-blue min-w-[58px] max-w-[130px] truncate">
                                    {item.label}
                                </span>
                                <i
                                    className="icon icon-close-pills cursor-pointer"
                                    onClick={() => {
                                        handleRemoveItem(item.id);
                                    }}
                                ></i>
                            </li>
                        ))}
                    </ul>
                </div>
                {modelsDataForTable?.nodes?.length > 0 ? (
                    <Table data={modelsDataForTable} theme={theme} className="w-full rounded  max-h-[calc(100vh-214px)] scroll-div scroll-smooth scroll-p-0">
                        {(tableList: Node[]) => (
                            <>
                                <Header className="">
                                    <HeaderRow className="">
                                        <HeaderCell className="text-unicon-lightBlue font-medium text-Hsm">
                                            Name
                                        </HeaderCell>
                                        <HeaderCell className="text-unicon-lightBlue font-medium text-Hsm">
                                            Provider
                                        </HeaderCell>
                                        <HeaderCell className="text-unicon-lightBlue font-medium text-Hsm">
                                            Version
                                        </HeaderCell>
                                        <HeaderCell className="text-unicon-lightBlue font-medium text-Hsm">
                                            Type
                                        </HeaderCell>
                                        <HeaderCell className="text-unicon-lightBlue font-medium text-Hsm">
                                            Added On
                                        </HeaderCell>
                                        <HeaderCell className="text-unicon-lightBlue font-medium text-Hsm">
                                            # Pools
                                        </HeaderCell>
                                        <HeaderCell className="text-unicon-lightBlue font-medium text-Hsm">
                                            Amount (USD)
                                        </HeaderCell>
                                        <HeaderCell className="text-unicon-lightBlue font-medium text-Hsm">
                                            Token Limit
                                        </HeaderCell>
                                        <HeaderCell className="text-unicon-lightBlue font-medium text-Hsm">
                                            Token Usage
                                        </HeaderCell>
                                    </HeaderRow>
                                </Header>

                                <Body>
                                    {tableList.map((item) => (
                                        <React.Fragment key={item.id}>
                                            <Row item={item}>
                                                <Cell className="text-unicon-lightBlue font-medium text-base">
                                                    <div
                                                        className="flex items-center justify-start cursor-pointer"
                                                        onClick={toggleModal}
                                                    >
                                                        <img
                                                            src={Microsoft}
                                                            alt="microsoft"
                                                            className="w-[22px] h-[22px] pr-[5px]"
                                                        />
                                                        <span className='text-unicon-lightBlue font-medium text-base block overflow-hidden text-ellipsis max-w-[124px]'>{item.name}</span>
                                                    </div>
                                                </Cell>
                                                <Cell className="text-unicon-lightBlue font-medium text-base">
                                                    {item.provider}
                                                </Cell>
                                                <Cell className="text-unicon-lightBlue font-medium text-base">
                                                    {item.version}
                                                </Cell>
                                                <Cell className="text-unicon-lightBlue font-medium text-base">
                                                    {item.type}
                                                </Cell>
                                                <Cell className="text-unicon-lightBlue font-medium text-base">
                                                    {item.added_on}
                                                </Cell>
                                                <Cell className="text-unicon-lightBlue font-medium text-base">
                                                    {item.pool}
                                                </Cell>
                                                <Cell className="text-unicon-lightBlue font-medium text-base">
                                                    {item.amounts}
                                                </Cell>
                                                <Cell className="text-unicon-lightBlue font-medium text-base">
                                                    {item.token_limit}
                                                </Cell>
                                                <Cell className="text-unicon-lightBlue font-medium text-base">
                                                    <ProgressBar value={20} max={100} />
                                                </Cell>
                                            </Row>
                                        </React.Fragment>
                                    ))}
                                </Body>
                            </>
                        )}
                    </Table>
                ) : (
                    <LoadingSpinner />
                )}
            </div>
            <footer className="font-medium text-base text italic h-10 flex items-center pl-2">
                <span className="text-[#C32B0A] text-xxl font-semibold">*</span> To add new model please reach out to{' '}
                <span className="not-italic text-unicon-blue pl-[2px]"> ChatBolt administrator</span>.
            </footer>

            <Modal
                isOpen={isModalOpen}
                onClose={toggleModal}
                showIcon={true}
                title="Model 1"
                subtitle="Chat completion"
            >
                <div className="modalbody w-full relative h-[calc(100vh-14.3rem)]">
                    <div className="modal-content-wrap h-[calc(100vh-19.3rem)] py-[30px] px-[30px]">
                        <h2 className="font-medium text-unicon-lightBlue text-xl mb-3">Pool Entitlement</h2>
                        <div
                            className="border border-solid border-unicon-grayBorder rounded h-[calc(100vh-420px)]"
                            style={{ boxShadow: '0px 1px 2px 0px #1018280F, 0px 1px 3px 0px #1018281A' }}
                        >
                            <Table data={data} theme={theme} className="w-full rounded">
                                {(tableList: Node[]) => (
                                    <>
                                        <Header className="">
                                            <HeaderRow className="">
                                                <HeaderCell className="text-unicon-lightBlue font-medium text-Hsm">
                                                    Pool Name
                                                </HeaderCell>
                                                <HeaderCell className="text-unicon-lightBlue font-medium text-Hsm">
                                                    No of Users
                                                </HeaderCell>
                                                <HeaderCell className="text-unicon-lightBlue font-medium text-Hsm">
                                                    Token Ent. (Yearly )
                                                </HeaderCell>
                                                <HeaderCell className="text-unicon-lightBlue font-medium text-Hsm">
                                                    {' '}
                                                    Token Ent. (Monthly )
                                                </HeaderCell>
                                                <HeaderCell className="text-unicon-lightBlue font-medium text-Hsm">
                                                    Token Usage
                                                </HeaderCell>
                                            </HeaderRow>
                                        </Header>

                                        <Body>
                                            {tableList.map((item) => (
                                                <React.Fragment key={item.id}>
                                                    <Row item={item}>
                                                        <Cell className="text-unicon-lightBlue font-medium text-base">
                                                            {item.pool_name}
                                                        </Cell>
                                                        <Cell className="text-unicon-lightBlue font-medium text-base text-right">
                                                            {item.no_of_users}
                                                        </Cell>
                                                        <Cell className="text-unicon-lightBlue font-medium text-base text-right">
                                                            {item.Token_Ent_yearly}
                                                        </Cell>
                                                        <Cell className="text-unicon-lightBlue font-medium text-base text-right">
                                                            {item.Token_Ent_month}
                                                        </Cell>
                                                        <Cell className="text-unicon-lightBlue font-medium text-base">
                                                            <ProgressBar value={20} max={100} />
                                                        </Cell>
                                                    </Row>
                                                </React.Fragment>
                                            ))}
                                        </Body>
                                    </>
                                )}
                            </Table>
                        </div>
                    </div>
                    <div className="modal-footer border-t border-solid border-unicon-modalBorder flex items-center justify-end gap-4 w-full h-20 rounded-b-xl absolute bottom-0 px-[30px]">
                        <button
                            className="btn secondary-btn"
                            onClick={() => {
                                setIsModalOpen(false);
                            }}
                        >
                            Cancel
                        </button>
                        <button
                            className="btn primary-btn"
                            onClick={() => {
                                setIsModalOpen(false);
                            }}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </Modal>
            
        </div>
    );
}
const ModelsTableLayout = withLayout(ModelsTable);

export default ModelsTableLayout;
