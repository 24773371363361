import React, { useState } from 'react';
import withLayout from './WithLayout';
import ModelChartCard from './ModelChartCard';
import Donutchart from './DonutChart';
import RunRateGrid from './RunRateGrid';
import Chartlineimg from '../../assets/Chart-line.svg';
import ChartDownlineimg from'../../assets/down-line-chart.svg';
const Dashboard: React.FC = () => {
    const [selectedOption, setSelectedOption] = useState<string>('30 days');
    const [isOpen, setIsOpen] = useState(false);
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    const handleOptionSelect = (option: string): void => {
        setSelectedOption(option);
        setIsOpen(false);
        return; 
      };

    const seriesDataForUsage = [
        {
            name: 'Model 1',
            data: [10, 41, 35, 51, 49, 62, 69, 91, 148, 158, 160, 170], // Steady increase
        },
        {
            name: 'Model 2',
            data: [15, 60, 70, 100, 120, 150, 180, 200, 250, 260, 280, 300], // Higher values, steeper increase
        },
        {
            name: 'Model 3',
            data: [25, 50, 80, 110, 140, 170, 200, 220, 250, 280, 300, 320], // Different curve, higher overall
        },
        {
            name: 'Model 4',
            data: [30, 80, 90, 120, 180, 240, 300, 350, 400, 420, 450, 480], // Highest values, aggressive growth
        },
    ];

    const users = [
        {
            id: 1,
            avatar: 'https://via.placeholder.com/150', // Placeholder image URL
            name: 'Albert Flore',
            email: 'albertflore@mail.com',
            amount: 6433,
        },
        {
            id: 2,
            avatar: 'https://via.placeholder.com/150',
            name: 'Jane Doe',
            email: 'janedoe@mail.com',
            amount: 7200,
        },
        {
            id: 3,
            avatar: 'https://via.placeholder.com/150',
            name: 'John Smith',
            email: 'johnsmith@mail.com',
            amount: 5400,
        },
        {
            id: 4,
            avatar: 'https://via.placeholder.com/150',
            name: 'Emma Johnson',
            email: 'emmajohnson@mail.com',
            amount: 8500,
        },
    ];
    
    return (
        <div>
            <div className='flex items-center justify-end h-9'>
                <div className='w-auto h-9 flex items-center justify-start bg-white border border-solid border-unicon-grayBorder rounded-lg mr-[30px]'  style={{boxShadow: '0px 1px 2px 0px #1018280D'}}>
                    <div className='flex items-center justify-start px-3 gap-2'>
                        <span className='text-base font-semibold text-unicon-gray'>Models  :</span>
                        <span className='flex items-center justify-between bg-unicon-chatLiHover text-base text-unicon-blue font-medium rounded-2xl gap-2 pl-3 pr-2 h-6 '>model 1
                            <i className='icon icon-close-pills cursor-pointer'></i>
                        </span>
                        <span className='flex items-center justify-between bg-unicon-chatLiHover text-base text-unicon-blue font-medium rounded-2xl gap-2 pl-3 pr-2 h-6 '>model 2
                            <i className='icon icon-close-pills cursor-pointer'></i>
                        </span>
                    </div>

                    <div className='flex items-center justify-start pl-3 pr-3 gap-2'>
                        <span className='text-base font-semibold text-unicon-gray'>Pools  :</span>
                        <span className='flex items-center justify-between bg-unicon-chatLiHover text-base text-unicon-blue font-medium rounded-2xl gap-2 pl-3 pr-2 h-6 '>model 1
                            <i className='icon icon-close-pills cursor-pointer'></i>
                        </span>
                        <span className='flex items-center justify-between bg-unicon-chatLiHover text-base text-unicon-blue font-medium rounded-2xl gap-2 pl-3 pr-2 h-6 '>model 2
                            <i className='icon icon-close-pills cursor-pointer'></i>
                        </span>
                    </div>
                </div>

                <button className='w-[110px] flex items-center justify-between h-9 gap-x-2.5 border border-solid border-unicon-grayBorder rounded-lg mr-[15px] pl-[12px] pr-[8px]'
                 style={{boxShadow: '0px 1px 2px 0px #1018280D'}}><span className='text-Hsm font-medium text-unicon-lightBlue'>Clear All </span><i className='icon icon-close'></i>
                 </button>

                <button className='flex items-center justify-center w-[91px] h-9 gap-x-2.5 border border-solid border-unicon-grayBorder rounded-lg mr-[15px] pl-[9px] pr-[14px]' 
                style={{boxShadow: '0px 1px 2px 0px #1018280D'}}>
                    <i className='icon icon-filter-modles'></i><span className='text-Hsm font-medium text-unicon-lightBlue'>Filters</span>
                    </button>

                    <button className='flex items-center justify-center min-w-[142px] h-9 gap-x-2.5 border border-solid border-unicon-grayBorder rounded-lg mr-[15px] pl-[12px] pr-[6px]' 
                style={{boxShadow: '0px 1px 2px 0px #1018280D'}}>
                     <i className='icon icon-calender-dashboard'></i>
                     <span className='text-Hsm font-medium text-unicon-lightBlue w-[77px] overflow-hidden text-ellipsis whitespace-nowrap block'>Current Year</span>
                     <i className='icon icon-arrowdown'></i>
                    </button>

                <button className='flex items-center justify-end'><i className='icon icon-excel icon-excel-dashboard'></i></button>
            </div>
            <div className='h-[calc(100vh-140px)] overflow-auto scroll-div scroll-smooth scroll-p-0'>
            <div className="grid grid-cols-4 gap-4 mt-2">
                <div className="bg-white  h-[150px] rounded-lg  border border-solid border-unicon-grayBorder p-5" style={{boxShadow: '0px 2px 10px 0px #7090B00F'}}>
                  <div className='flex items-center justify-between'>
                    <div className='inline-flex items-center justify-start'>
                        <i className='icon icon-microsoft'></i>
                        <span className='text-unicon-lightBlue text-base font-medium  px-1'>Model 1</span>
                        <i className='icon icon-modelinfo'></i>
                        </div>
                        <div className='relative'>
                            <button className='bg-unicon-daysDropdown rounded pl-2 px-1 flex items-center justify-between h-[20px]' onClick={toggleDropdown}>
                                <span className='font-normal text-sm text-unicon-gray leading-4  min-w-[36px] pr-1'>{selectedOption}</span><i className='icon icon-arrowdown'></i>
                            </button>
                        {isOpen && (
                            <ul className="py-2 absolute top-5 right-0 w-full border border-solid border-unicon-daysDropdown z-20 bg-white" style={{boxShadow: '0px 12px 16px -4px #10182814, 0px 4px 6px -2px #10182808'}}>
                            <li className=" cursor-pointer flex items-center justify-start p-2  font-normal text-sm text-unicon-gray leading-4 h-[20px] hover:bg-unicon-chatLiHover hover:text-unicon-lightBlue" onClick={() => { handleOptionSelect('30 days'); }}
                            >30 days</li>
                            <li className="cursor-pointer flex items-center justify-start p-2 font-normal text-sm text-unicon-gray leading-4 h-[20px] hover:bg-unicon-chatLiHover hover:text-unicon-lightBlue" onClick={() => { handleOptionSelect('14 days'); }}
                            >14 days</li>
                            <li className="cursor-pointer flex items-center justify-start p-2 font-normal text-sm text-unicon-gray leading-4 h-[20px] hover:bg-unicon-chatLiHover hover:text-unicon-lightBlue"  onClick={() => { handleOptionSelect('10 days'); }}
                            >10 days</li>
                            
                            </ul>
                    )}
                        </div>
                    </div>  
                    <div className='flex items-center justify-between mt-[36px]'>
                        <div className='inner-left'>
                            <div className='font-semibold text-xxl text-unicon-lightBlue whitespace-nowrap'>$2,433 <span className='font-medium text-base'>($10,000)</span>
                            <span className='bg-unicon-trendBg inline-flex items-center justify-center h-[19px] px-[6px] gap-x-1 rounded ml-2'>
                                <i className='icon iconTreandUp'></i>
                                <span className='text-sm font-medium text-unicon-green'>1.22%</span>
                            </span>
                            </div>
                            <div className='inline-flex items-center justify-start pt-1.5'>
                                <i className='icon icon-currency'></i><span className='text-unicon-gray font-medium text-base'>3,500 <span className='text-sm'>(10,000)</span>
                                </span>
                            </div>
                        </div> 
                        <div className='inner-right w-[70px] h-[60px] relative'>
                            <img src={Chartlineimg} alt="line"/>
                        </div> 
                    </div>
                </div>

               <div className="bg-white  h-[150px] rounded-lg  border border-solid border-unicon-grayBorder p-5" style={{boxShadow: '0px 2px 10px 0px #7090B00F'}}>
                  <div className='flex items-center justify-between'>
                    <div className='inline-flex items-center justify-start'>
                        <i className='icon icon-microsoft'></i>
                        <span className='text-unicon-lightBlue text-base font-medium  px-1'>Model 2</span>
                        <i className='icon icon-modelinfo'></i>
                        </div>
                        <div className='relative'>
                            <button className='bg-unicon-daysDropdown rounded pl-2 px-1 flex items-center justify-between h-[20px]' onClick={toggleDropdown}>
                                <span className='font-normal text-sm text-unicon-gray leading-4  min-w-[36px] pr-1'>{selectedOption}</span><i className='icon icon-arrowdown'></i>
                            </button>
                        {isOpen && (
                            <ul className="py-2 absolute top-5 right-0 w-full border border-solid border-unicon-daysDropdown z-20 bg-white" style={{boxShadow: '0px 12px 16px -4px #10182814, 0px 4px 6px -2px #10182808'}}>
                            <li className=" cursor-pointer flex items-center justify-start p-2  font-normal text-sm text-unicon-gray leading-4 h-[20px] hover:bg-unicon-chatLiHover hover:text-unicon-lightBlue" onClick={() => { handleOptionSelect('30 days'); }}
                            >30 days</li>
                            <li className="cursor-pointer flex items-center justify-start p-2 font-normal text-sm text-unicon-gray leading-4 h-[20px] hover:bg-unicon-chatLiHover hover:text-unicon-lightBlue" onClick={() => { handleOptionSelect('14 days'); }}
                            >14 days</li>
                            <li className="cursor-pointer flex items-center justify-start p-2 font-normal text-sm text-unicon-gray leading-4 h-[20px] hover:bg-unicon-chatLiHover hover:text-unicon-lightBlue"  onClick={() => { handleOptionSelect('10 days'); }}
                            >10 days</li>
                            </ul>
                    )}
                        </div>
                    </div>  
                    <div className='flex items-center justify-between mt-[36px]'>
                        <div className='inner-left'>
                            <div className='font-semibold text-xxl text-unicon-lightBlue whitespace-nowrap'>$2,433 <span className='font-medium text-base'>($10,000)</span>
                            <span className='bg-unicon-trendBg inline-flex items-center justify-center h-[19px] px-[6px] gap-x-1 rounded ml-2'>
                                <i className='icon iconTreandUp'></i>
                                <span className='text-sm font-medium text-unicon-green'>1.22%</span>
                            </span>
                            </div>
                            <div className='inline-flex items-center justify-start pt-1.5'>
                                <i className='icon icon-currency'></i><span className='text-unicon-gray font-medium text-base'>3,500 <span className='text-sm'>(10,000)</span>
                                </span>
                            </div>
                        </div> 
                        <div className='inner-right w-[70px] h-[60px]'>
                            <img src={Chartlineimg} alt="line"/>
                        </div> 
                    </div>
                </div>

                <div className="bg-white  h-[150px] rounded-lg  border border-solid border-unicon-grayBorder p-5" style={{boxShadow: '0px 2px 10px 0px #7090B00F'}}>
                  <div className='flex items-center justify-between'>
                    <div className='inline-flex items-center justify-start'>
                        <i className='icon icon-microsoft'></i>
                        <span className='text-unicon-lightBlue text-base font-medium  px-1'>Model 3</span>
                        <i className='icon icon-modelinfo'></i>
                        </div>
                        <div className='relative'>
                            <button className='bg-unicon-daysDropdown rounded pl-2 px-1 flex items-center justify-between h-[20px]' onClick={toggleDropdown}>
                                <span className='font-normal text-sm text-unicon-gray leading-4  min-w-[36px] pr-1'>{selectedOption}</span><i className='icon icon-arrowdown'></i>
                            </button>
                        {isOpen && (
                            <ul className="py-2 absolute top-5 right-0 w-full border border-solid border-unicon-daysDropdown bg-white z-20" style={{boxShadow: '0px 12px 16px -4px #10182814, 0px 4px 6px -2px #10182808'}}>
                            <li className=" cursor-pointer flex items-center justify-start p-2  font-normal text-sm text-unicon-gray leading-4 h-[20px] hover:bg-unicon-chatLiHover hover:text-unicon-lightBlue" onClick={() => { handleOptionSelect('30 days'); }}
                            >30 days</li>
                            <li className="cursor-pointer flex items-center justify-start p-2 font-normal text-sm text-unicon-gray leading-4 h-[20px] hover:bg-unicon-chatLiHover hover:text-unicon-lightBlue" onClick={() => { handleOptionSelect('14 days'); }}
                            >14 days</li>
                            <li className="cursor-pointer flex items-center justify-start p-2 font-normal text-sm text-unicon-gray leading-4 h-[20px] hover:bg-unicon-chatLiHover hover:text-unicon-lightBlue"  onClick={() => { handleOptionSelect('10 days'); }}
                            >10 days</li>
                            
                            </ul>
                    )}
                        </div>
                    </div>  
                    <div className='flex items-center justify-between mt-[36px]'>
                        <div className='inner-left'>
                            <div className='font-semibold text-xxl text-unicon-lightBlue whitespace-nowrap'>$2,433 <span className='font-medium text-base'>($10,000)</span>
                            <span className='bg-unicon-trendDownBg inline-flex items-center justify-center h-[19px] px-[6px] gap-x-1 rounded ml-2'>
                                <i className='icon iconTreandDownIcon'></i>
                                <span className='text-sm font-medium text-unicon-trendDownColor'>2.90%</span>
                            </span>
                            </div>
                            <div className='inline-flex items-center justify-start pt-1.5'>
                                <i className='icon icon-currency'></i><span className='text-unicon-gray font-medium text-base'>3,500 <span className='text-sm'>(10,000)</span>
                                </span>
                            </div>
                        </div> 
                        <div className='inner-right w-[70px] h-[60px]'>
                            <img src={ChartDownlineimg} alt="line"/>
                        </div> 
                    </div>
                </div>

                <div className="bg-white  h-[150px] rounded-lg  border border-solid border-unicon-grayBorder p-5 relative" style={{boxShadow: '0px 2px 10px 0px #7090B00F'}}>
                <div className='model-not-avi'></div>
                    <div className='flex items-center justify-start model-not-avi-content text-base font-medium text-unicon-lightBlue'><i className='icon icon-info-red mr-2'></i>Model not available</div>
                <div className='flex items-center justify-between'>
                    <div className='inline-flex items-center justify-start'>
                        <i className='icon icon-microsoft'></i>
                        <span className='text-unicon-lightBlue text-base font-medium  px-1'>Model 4</span>
                        <i className='icon icon-modelinfo'></i>
                        </div>
                        <div className='relative'>
                            <button className='bg-unicon-daysDropdown rounded pl-2 px-1 flex items-center justify-between h-[20px]' onClick={toggleDropdown}>
                                <span className='font-normal text-sm text-unicon-gray leading-4  min-w-[36px] pr-1'>{selectedOption}</span><i className='icon icon-arrowdown'></i>
                            </button>
                        {isOpen && (
                            <ul className="py-2 absolute top-5 right-0 w-full border border-solid border-unicon-daysDropdown bg-white z-20" style={{boxShadow: '0px 12px 16px -4px #10182814, 0px 4px 6px -2px #10182808'}}>
                            <li className=" cursor-pointer flex items-center justify-start p-2  font-normal text-sm text-unicon-gray leading-4 h-[20px] hover:bg-unicon-chatLiHover hover:text-unicon-lightBlue" onClick={() => { handleOptionSelect('30 days'); }}
                            >30 days</li>
                            <li className="cursor-pointer flex items-center justify-start p-2 font-normal text-sm text-unicon-gray leading-4 h-[20px] hover:bg-unicon-chatLiHover hover:text-unicon-lightBlue" onClick={() => { handleOptionSelect('14 days'); }}
                            >14 days</li>
                            <li className="cursor-pointer flex items-center justify-start p-2 font-normal text-sm text-unicon-gray leading-4 h-[20px] hover:bg-unicon-chatLiHover hover:text-unicon-lightBlue"  onClick={() => { handleOptionSelect('10 days'); }}
                            >10 days</li>
                            
                            </ul>
                    )}
                        </div>
                    </div>  
                    <div className='flex items-center justify-between mt-[36px]'>
                        <div className='inner-left'>
                            <div className='font-semibold text-xxl text-unicon-lightBlue whitespace-nowrap'>$2,433 <span className='font-medium text-base'>($10,000)</span>
                            <span className='bg-unicon-trendBg inline-flex items-center justify-center h-[19px] px-[6px] gap-x-1 rounded ml-2'>
                                <i className='icon iconTreandUp'></i>
                                <span className='text-sm font-medium text-unicon-green'>1.22%</span>
                            </span>
                            </div>
                            <div className='inline-flex items-center justify-start pt-1.5'>
                                <i className='icon icon-currency'></i><span className='text-unicon-gray font-medium text-base'>3,500 <span className='text-sm'>(10,000)</span>
                                </span>
                            </div>
                        </div> 
                        <div className='inner-right w-[70px] h-[60px]'>
                            <img src={Chartlineimg} alt="line"/>
                        </div> 
                    </div>
                </div>
                
                <div className="bg-white h-[360px]  col-span-3 rounded-lg border border-solid border-unicon-grayBorder px-3 pt-1" style={{boxShadow: '0px 2px 10px 2px #7090B00F'}}>
                    {' '}
                    <ModelChartCard modelName="Model Usage" series={seriesDataForUsage} height={300} />
                </div>
                <div className="bg-white h-[360px] rounded-lg border border-solid border-unicon-grayBorder" style={{boxShadow: '0px 2px 10px 2px #7090B00F'}}>
                    <Donutchart />
                </div>

                <div className="bg-white col-span-3 px-5 pt-1 pb-5 rounded-lg border border-solid border-unicon-grayBorder" style={{boxShadow: '0px 2px 10px 2px #7090B00F'}}>
                    <RunRateGrid />
                </div>
                <div className="bg-white  p-4 rounded-lg border border-solid border-unicon-grayBorder" style={{boxShadow: '0px 2px 10px 2px #7090B00F'}}>
                    <div className="w-full flex justify-between items-center">
                        <h3 className="flex items-center justify-start font-semibold text-unicon-lightBlue text-xl">Top Users<i className='icon icon-modelinfo ml-1' ></i></h3>
                        <h4 className="font-medium text-unicon-blue text-base">View all</h4>
                    </div>
                <div className='h-[calc(100vh-576px)] scroll-div scroll-smooth scroll-p-0 pt-2 mt-6'>
                    {users.map((user) => (
                        <div key={user.id} className="flex gap-6 items-center  border-b border-solid border-unicon-grayBorder pb-4 pt-3 justify-between ">
                            <div className="flex items-center justify-start gap-3">
                                <img src={user.avatar} alt={user.name} className="w-9 h-9 rounded-full" />
                                <div>
                                    <h3 className="font-medium text-unicon-lightBlue text-base">{user.name}</h3>
                                    <h3 className="font-normal text-base text-unicon-gray">{user.email}</h3>
                                </div>
                            </div>

                            <div className="font-semibold text-xl text-unicon-lightBlue">$ {user.amount.toLocaleString()}</div>
                        </div>
                    ))}
                </div>    
                </div>
            </div>
            </div>
        </div>
    );
};

const DashboardWithLayout = withLayout(Dashboard);

export default DashboardWithLayout;
