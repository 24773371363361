// import React, { useState } from 'react';
import { Table, Header, HeaderRow, Body, Row, HeaderCell, Cell } from '@table-library/react-table-library/table';
import { useTheme } from '@table-library/react-table-library/theme';
import Avatar from '../../assets/Avatar.svg';
import withLayout from '../dashboard/WithLayout';
import { useState } from 'react';

interface Node {
    id: string;
    name: string;
    user_id:number;
    Activity: string;
    Timestamp: string;
    IP_address:string;
    Details:string;
}
interface Data {
    nodes: Node[];
}

const nodes: Node[] = [
    {
        id: '1',
        name: 'Albert Flore',
        user_id:232123,
    Activity: 'Logged in',
    Timestamp: '23 Feb, 2024  |  10:15 AM ',
    IP_address:'192.168.1.100',
    Details:'Succesful login from home network',
    },
    {
        id: '2',
        name: 'Jane Cooper',
        user_id:232123,
    Activity: 'Archived chat',
    Timestamp: '23 Feb, 2024  |  10:00 AM  ',
    IP_address:'192.244.1.100',
    Details:'Archived chat ID 22349',
    },
    {
        id: '3',
        name: 'Jenny Wilson',
        user_id:232123,
        Activity: 'Archived chat',
        Timestamp: '23 Feb, 2024  |  10:00 AM  ',
        IP_address:'192.168.1.432',
        Details:'Archived chat ID 23849',
    },

    {
        id: '4',
        name: 'Ken Smith',
        user_id:232123,
        Activity: 'Deleted chat',
        Timestamp: '23 Feb, 2024  |  10:00 AM  ',
        IP_address:'134.168.1.100',
        Details:'Deleted chat ID 77749',
    },

    {
        id: '5',
        name: 'Mike Cole',
        user_id:232123,
        Activity: 'Logged in',
        Timestamp: '27 Feb, 2024  |  06:15 PM   ',
        IP_address:'192.146.1.100',
        Details:'Succesful login ',
    },
    {
        id: '6',
        name: 'Jacob Jones',
        user_id:232123,
        Activity: 'Edited profile',
        Timestamp: '28 Feb, 2024  |  10:00 AM   ',
        IP_address:'192.168.1.100',
        Details:'Succesful login ',
    },
];

function ActivityLogTables() {
    const data: Data = { nodes };  
    const [filterItems, setFilterItems] = useState([
        { id: 1, label: 'model 3' },
        { id: 2, label: 'model 4' },
      ]);
      const handleRemoveItem = (id: number) => {
        setFilterItems(filterItems.filter((item) => item.id !== id));
      };
     
    const theme = useTheme({
        HeaderRow: `
        .th {
         border-bottom:1px solid #EAECF0;
         padding:12px 24px;
         background-color: #F6F7FA;
         height:44px;
        }
      `,
    BaseCell: `
       &:first-of-type {
       >*{display:flex;}
       }
       
        &.icon-cell >div{
        display:inline-flex;
        align-item:center;
        justify-content:end;
       }
       border-bottom:1px solid #EAECF0;
       padding:12px 24px;
       height:60px;
      `,
    });

    return (
        <div>
            <div className="flex justify-between items-center h-[60px]">
                <div className='inputwrap h-10 flex items-center justify-start relative'>
                    <i className='icon icon-input-search absolute left-0 cursor-pointer'></i>
                <input className="min-w-[332px] h-[32px] border-b border-[#D0D5DD] border-solid focus-visible:outline-none pl-[26px] text-sm text-unicon-lightBlue font-medium" type="text" placeholder='Search users/roles..' />
                <button className={`w-10 h-10 flex items-center justify-center relative  ${filterItems.length >= 1 ? 'filter-active-icon' : ''} `} style={{transition:'all 0.3s ease'}}>
                    <i className='icon icon-filter'></i>
                </button>
                </div>
                <div className='flex items-center justify-end'>
                    <div className='min-w-[170px] h-[36px] flex items-center justify-start rounded-lg border border-solid border-unicon-grayBorder gap-[6px] pl-[9px] pr-[14px]' style={{boxShadow: '0px 1px 2px 0px #1018280D'}}>
                        <i className='icon icon-calendar cursor-pointer'></i>
                        <input className="text-unicon-lightBlue text-[13px] font-medium h-8 focus-visible:outline-none" type='text' value='Feb 19- Feb 29, 2024'  readOnly/>
                    </div>
                </div>
            </div>
            <div className='border border-solid border-unicon-grayBorder rounded h-[calc(100vh-195px)]' style={{boxShadow: '0px 1px 2px 0px #1018280F, 0px 1px 3px 0px #1018281A'}}>
            <div className={`filter-list-wrpa flex justify-start py-3 px-6 min-h-[56px] ${filterItems.length === 0 ? 'hidden' : ''}`}  style={{transition:'all 0.3s ease'}}>
                    <span className='font-semibold text-base text-unicon-gray w-[65px] m-auto'>Filters  :</span>
                    <ul className="scroll-div inline-flex justify-start overflow-x-hidden overflow-y-auto gap-3 max-h-[100px] flex-wrap w-[calc(100%-65px)]">
                            {filterItems.map((item) => (
                                <li
                                key={item.id}
                                className="flex items-center justify-between h-7 rounded-2xl gap-[0.5rem] py-4 pl-3 pr-2 bg-unicon-chatLiHover"
                                >
                                <span className="font-medium text-base text-unicon-blue min-w-[58px] max-w-[130px] truncate">
                                    {item.label}
                                </span>
                                <i
                                    className="icon icon-close-pills cursor-pointer"
                                    onClick={() => {handleRemoveItem(item.id)}}
                                ></i>
                                </li>
                            ))}
                            </ul>
                </div>
                <Table data={data} theme={theme} className="w-full rounded max-h-[calc(100vh-268px)] scroll-div scroll-smooth scroll-p-0">
                    {(tableList: Node[]) => (
                        <>
                            <Header className="">
                                <HeaderRow className="">
                                    <HeaderCell className="text-unicon-lightBlue font-medium text-Hsm" title="User Name">User Name</HeaderCell>
                                    <HeaderCell className="text-unicon-lightBlue font-medium text-Hsm" title="Activity">Activity</HeaderCell>
                                    <HeaderCell className="text-unicon-lightBlue font-medium text-Hsm" title="Timestamp">Timestamp</HeaderCell>
                                    <HeaderCell className="text-unicon-lightBlue font-medium text-Hsm" title="IP Address">IP Address</HeaderCell>
                                    <HeaderCell className="text-unicon-lightBlue font-medium text-Hsm" title="Details">Details</HeaderCell>
                                </HeaderRow>
                            </Header>

                            <Body>
                                {tableList.map((item) => (
                                    <Row item={item} key={item.id}>
                                                <Cell className="text-unicon-lightBlue font-medium text-base">
                                                    <div className='flex items-center justify-start'>
                                                        <span className='w-9 h-9 flex items-center justify-start' title={item.name.toString()}><img src={Avatar} alt="Avatar" className='w-[36px] h-[36px]' /></span>
                                                        <div className='font-medium text-unicon-lightBlue text-base leading-[18px] pl-[10px]' title={item.name.toString()}>{item.name}
                                                            <br/><span className='text-unicon-gray text-sm leading-none' title={item.user_id.toString()}>User ID:{item.user_id}</span></div></div>
                                                    </Cell>
                                                <Cell className="text-unicon-lightBlue font-medium text-base" title={item.Activity.toString()}>{item.Activity}</Cell>
                                                <Cell className="text-unicon-lightBlue font-medium text-base" title={item.Timestamp.toString()}>{item.Timestamp}</Cell>
                                                <Cell className="text-unicon-lightBlue font-medium text-base" title={item.IP_address.toString()}>{item.IP_address}</Cell>
                                                <Cell className="text-unicon-lightBlue font-medium text-base" title={item.Details.toString()}>
                                                    {item.Details}
                                                </Cell>
                                            </Row>
                                    

                                    
                                ))}
                            </Body>
                        </>
                    )}
                </Table>
            </div>
            
          
        </div>
    );
}
const ActivityLogswithLayout = withLayout(ActivityLogTables);

export default ActivityLogswithLayout;
