import ChatHeader from './ChatHeader';
import { ChatRoom } from './ChatRoom';

interface chatWindowProps {
    modelsList: any;
    selectedModelName: string;
    updateSelectedModel: any;
}

function ChatWindow() {
    return (
        <div className="flex w-[calc(100%-380px)] flex-col 2xl:w-[calc(100%-380px)] xl:w-[calc(100%-350px)] lg:w-[calc(100%-320px)] md:w-[calc(100%-300px)]">
            <ChatHeader />
            <ChatRoom />
        </div>
    );
}

export default ChatWindow;
