import { useIsAuthenticated } from '@azure/msal-react';
import { useAppSelector } from '../redux/app/hooks';
import { RootState } from '../redux/app/store';
import { IChatUser } from '../libs/models/ChatUser';
import { ToastContainer } from 'react-toastify';

export const PrivateRoute = ({ children }: { children: JSX.Element }) => {
    // const location = useLocation();
    const isAuthenticated = useIsAuthenticated();
    const { activeUserInfo } = useAppSelector((state: RootState) => state.app);
    const userId = activeUserInfo?.id ?? '';
    const fullName = activeUserInfo?.username ?? '';
    const emailAddress = activeUserInfo?.email ?? '';

    const loggedInUser: IChatUser = {
        id: userId,
        fullName,
        emailAddress,
        photo: undefined,
        online: true,
        isTyping: false,
    };

    if (isAuthenticated && !activeUserInfo) {
        return <p className="container">Checking auth..</p>;
    }

    // const userHasRequiredRole = loggedInUser && roles.includes(loggedInUser.role) ? true : false;

    // if (!isAuthenticated) {
    //     return <Navigate to="/login" state={{ from: location }} />;
    // }

    // if (isAuthenticated && !userHasRequiredRole) {
    //     return <div>you don't have access</div>;
    // }

    return (
        <div>
            <ToastContainer />
            {children}
        </div>
    );
};
