import { Table, Header, HeaderRow, Body, Row, HeaderCell, Cell } from '@table-library/react-table-library/table';
import { useTheme } from '@table-library/react-table-library/theme';
import React from 'react';
import ProgressBar from '../utils/ProgressBar';
import Microsoft from '../../assets/microsoft.svg'

interface Node {
    id: string;
    name: string;
    jan:string;
    feb:string;
    mar:string;
    apr:string;
    may:string;
    jun:string;  
    jul:string;
    aug:string;
    sep:string;
    oct:string;
    nov:string;
    dec:string;
    total:string;
}
interface Data {
    nodes: Node[];
}

const nodes: Node[] = [
    {
        id: '1',
        name: 'Model 1',
        jan:' $50 ',
        feb:' $10 ',
        mar:' $10 ',
        apr:' $10 ',
        may:' $10 ',
        jun:' --',
        jul:' $50 ',
        aug:' -- ',
        sep:'$20 ',
        oct:' $20 ',
        nov:' $12.2k ',
        dec:' $10k ',
        total:'$232 ',
    },
    {
        id: '2',
        name: 'Model 2',
        jan:' $50 ',
        feb:' $10 ',
        mar:' $10 ',
        apr:' $10 ',
        may:' $10 ',
        jun:' --',
        jul:' $50 ',
        aug:' -- ',
        sep:'$20 ',
        oct:' $20 ',
        nov:' $12.2k ',
        dec:' $10k ',
        total:'$232 ',
    },
    {
        id: '3',
        name: 'Model 3',
        jan:' $50 ',
        feb:' $10 ',
        mar:' $10 ',
        apr:' $10 ',
        may:' $10 ',
        jun:' --',
        jul:' $50 ',
        aug:' -- ',
        sep:'$20 ',
        oct:' $20 ',
        nov:' $12.2k ',
        dec:' $10k ',
        total:'$232 ',
    },
    {
        id: '4',
        name: 'Model 4',
        jan:' $50 ',
        feb:' $10 ',
        mar:' $10 ',
        apr:' $10 ',
        may:' $10 ',
        jun:' --',
        jul:' $50 ',
        aug:' -- ',
        sep:'$20 ',
        oct:' $20 ',
        nov:' $12.2k ',
        dec:' $10k ',
        total:'$232 ',
    },
    {
        id: '5',
        name: 'Total',
        jan:' $200 ',
        feb:' $40 ',
        mar:' $40 ',
        apr:' $40 ',
        may:' $40 ',
        jun:' --',
        jul:' $200 ',
        aug:' -- ',
        sep:'$80 ',
        oct:' $80 ',
        nov:' $48.8k ',
        dec:' $40k ',
        total:'',
    },
];


const RunRateGrid: React.FC = () => {
    const data: Data = { nodes };

    const [ids, setIds] = React.useState<string[]>([]);

    const handleExpand = (item: Node) => {
        if (ids.includes(item.id)) {
            setIds(ids.filter((id) => id !== item.id));
        } else {
            setIds(ids.concat(item.id));
        }
    };

    const theme = useTheme({
        HeaderRow: `
        .th {
         border-top:1px solid #EAECF0;
         border-bottom:1px solid #EAECF0;
         padding:12px 14px;
         background-color: #fff!important;
         height:44px;
         &:first-child {
        border-left: 1px solid #EAECF0;
        border-right: 1px solid #EAECF0;
        }
         &:last-child {
        border-left: 1px solid #EAECF0;
        border-right: 1px solid #EAECF0;
        }
         &:last-child >div{
         text-align:right;}
        }
          
        
      `,
        BaseCell: `
       &:first-of-type {
       >*{display:flex;}
       }
       
        &.icon-cell >div{
        display:inline-flex;
        align-item:center;
        justify-content:end;
       }

       border-bottom:0px solid #EAECF0;
       padding:12px 14px;
       height:60px;
       text-align:center;
        &:nth-child(odd) {
                background-color: #ffffff; 
            }
            &:nth-child(even) {
                background-color: #FCFCFC;
            }
        &:first-child {
        border-left: 1px solid #EAECF0;
        border-right: 1px solid #EAECF0;
        }
         &:last-child {
        border-left: 1px solid #EAECF0;
        border-right: 1px solid #EAECF0;
        background-color: #fff;
        }
         &:last-child >div{
         text-align:right;   
        }
         tbody .tr:last-of-type & {
            font-size: 16px!important;
            font-weight: 500;
            border-top: 1px solid #EAECF0;
            border-bottom: 1px solid #EAECF0;
            background-color: #fff;
        }
      `,
    });

    return (
        <div>
            <div className='flex items-center justify-between'>
            <h3 className='font-semibold text-unicon-lightBlue text-xl flex items-center justify-start '>Run Rate Grid<i className='icon icon-modelinfo ml-1' ></i></h3>
                <div className='flex items-center justify-end'>
                    <span className='text-sm font-medium text-unicon-lightBlue italic mr-2'>
                        <span className='text-unicon-trendDownColor text-base font-normal'>*</span>Values are in thousands</span>
                    <div className='flex items-center justify-between border border-solid border-unicon-grayBorder rounded-md'>
                        <button className='h-[30px] w-[30px] flex items-center justify-center rounded-l bg-unicon-white text-base active-btn'>$</button>
                        <button className='h-[30px] w-[30px] flex items-center justify-center rounded-r bg-unicon-white '>
                            <i className='icon icon-currency '></i>
                        </button>
                    </div>       
                </div>

            </div>
            <Table  data={data} theme={theme} className="w-full rounded  max-h-[calc(100vh-500px)] scroll-div scroll-smooth scroll-p-0 pt-1">
                        {(tableList: Node[]) => (
                            <>
                                <Header className="">
                                    <HeaderRow className="">
                                        <HeaderCell className="text-unicon-lightBlue font-semibold text-xl">
                                        Models
                                        </HeaderCell>
                                        <HeaderCell className="text-unicon-lightBlue font-medium text-Hsm">
                                        Jan’24
                                        </HeaderCell>
                                        <HeaderCell className="text-unicon-lightBlue font-medium text-Hsm">
                                        Feb’24
                                        </HeaderCell>
                                        <HeaderCell className="text-unicon-lightBlue font-medium text-Hsm">
                                            Mar’24
                                        </HeaderCell>
                                        <HeaderCell className="text-unicon-lightBlue font-medium text-Hsm">
                                        Apr’24
                                        </HeaderCell>
                                        <HeaderCell className="text-unicon-lightBlue font-medium text-Hsm">
                                        May’24
                                        </HeaderCell>
                                        <HeaderCell className="text-unicon-lightBlue font-medium text-Hsm">
                                        Jun’24
                                        </HeaderCell>
                                        <HeaderCell className="text-unicon-lightBlue font-medium text-Hsm">
                                        Jul’24
                                        </HeaderCell>
                                        <HeaderCell className="text-unicon-lightBlue font-medium text-Hsm">
                                        Aug’24
                                        </HeaderCell>
                                        <HeaderCell className="text-unicon-lightBlue font-medium text-Hsm">
                                        Sep’24
                                        </HeaderCell>
                                        <HeaderCell className="text-unicon-lightBlue font-medium text-Hsm">
                                        Oct’24
                                        </HeaderCell>
                                        <HeaderCell className="text-unicon-lightBlue font-medium text-Hsm">
                                        Nov’24
                                        </HeaderCell>
                                        <HeaderCell className="text-unicon-lightBlue font-medium text-Hsm">
                                        Dec’24
                                        </HeaderCell>
                                        <HeaderCell className="text-unicon-lightBlue font-semibold text-xl text-right">
                                            Total
                                        </HeaderCell>
                                    </HeaderRow>
                                </Header>

                                <Body>
                                    {tableList.map((item) => (
                                        <React.Fragment key={item.id}>
                                            <Row item={item}>
                                                <Cell className="text-unicon-lightBlue font-medium text-base">
                                                    <div
                                                        className="flex items-center justify-start cursor-pointer">
                                                        <img
                                                            src={Microsoft}
                                                            alt="microsoft"
                                                            className="w-[22px] h-[22px] pr-[5px]"
                                                        />
                                                        <span className='text-unicon-lightBlue font-medium text-base block overflow-hidden text-ellipsis max-w-[124px]'>{item.name}</span>
                                                    </div>
                                                </Cell>
                                                <Cell className="text-unicon-lightBlue font-normal text-base">
                                                    {item.jan}
                                                </Cell>
                                                <Cell className="text-unicon-lightBlue font-normal text-base">
                                                    {item.feb}
                                                </Cell>
                                                <Cell className="text-unicon-lightBlue font-normal text-base">
                                                    {item.mar}
                                                </Cell>
                                                <Cell className="text-unicon-lightBlue font-normal text-base">
                                                    {item.apr}
                                                </Cell>
                                                <Cell className="text-unicon-lightBlue font-normal text-base">
                                                    {item.may}
                                                </Cell>
                                                <Cell className="text-unicon-lightBlue font-normal text-base">
                                                    {item.jun}
                                                </Cell>
                                                <Cell className="text-unicon-lightBlue font-normal text-base">
                                                    {item.jul}
                                                </Cell>
                                                <Cell className="text-unicon-lightBlue font-normal text-base">
                                                    {item.aug}
                                                </Cell>
                                                <Cell className="text-unicon-lightBlue font-normal text-base">
                                                {item.sep}
                                                </Cell>
                                                <Cell className="text-unicon-lightBlue font-normal text-base">
                                                {item.oct}
                                                </Cell>
                                                <Cell className="text-unicon-lightBlue font-normal text-base">
                                                {item.nov}
                                                </Cell>
                                                <Cell className="text-unicon-lightBlue font-normal text-base">
                                                {item.dec}
                                                </Cell>
                                                <Cell className="text-unicon-lightBlue font-medium text-xl">
                                                {item.total}
                                                </Cell>
                                            </Row>
                                        </React.Fragment>
                                    ))}
                                </Body>
                            </>
                        )}
                    </Table>
        </div>
    );
};


export default RunRateGrid;
