import { Model } from "../models/ModelsType";
import { BaseService } from "./BaseService";

export class ModelService extends BaseService {
    public getModelsAsync = async (accessToken:string): Promise<Model[]> => {
       
        const result = await this.getResponseAsync<Model[]>(
            {
                commandPath: 'chatmodel',
                method: 'GET',
            },
            accessToken,
        );

        return result;
    };
    
    public getUserEntitledModels = async (accessToken:string,userId): Promise<Model[]> => {
       
        const result = await this.getResponseAsync<Model[]>(
            {
                commandPath: `userentitlement/${userId}`,
                method: 'GET',
            },
            accessToken,
        );

        return result;
    };
}
