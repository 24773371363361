import { Table, Header, HeaderRow, Body, Row, HeaderCell, Cell } from '@table-library/react-table-library/table';
import { useTheme } from '@table-library/react-table-library/theme';
import ProgressBar from '../utils/ProgressBar';
import { useState } from 'react';

interface Node {
    id: string;
    name: string;
    no_of_users: number;
    Token_Ent_yearly: number;
    Token_Ent_month: number;
    token_usage: number;
}
interface Data {
    nodes: Node[];
}

function ModelsTable1({
    poolName,
    noOfUsers,
    isHidden,
    tokenEntYearly,
    tokenEntMonthly,
    model,
    updateTokenEntitlement,
}: any) {
    const nodes: Node[] = [
        {
            id: '1',
            name: poolName,
            no_of_users: noOfUsers,
            Token_Ent_yearly: tokenEntYearly,
            Token_Ent_month: tokenEntMonthly,
            token_usage: 0,
        },
    ];

    const data: Data = { nodes };
    const theme = useTheme({
        HeaderRow: `
        .th {
         border-bottom:1px solid #EAECF0;
         padding:12px 24px;
         background-color: #F6F7FA;
         height:44px;
        }
      `,
        BaseCell: `
       &:first-of-type {
       >*{display:flex;}
       }
       
        &.icon-cell >div{
        display:inline-flex;
        align-item:center;
        justify-content:end;
       }
       border-bottom:1px solid #EAECF0;
       padding:12px 24px;
       height:60px;
      `,
    });

    const { id } = model;
    return (
        <div className={isHidden ? 'hidden' : ''}>
            <div
                className="border border-solid border-unicon-grayBorder rounded "
                style={{ boxShadow: '0px 1px 2px 0px #1018280F, 0px 1px 3px 0px #1018281A' }}
            >
                <Table data={data} theme={theme} className="w-full rounded">
                    {(tableList: Node[]) => (
                        <>
                            <Header className="">
                                <HeaderRow className="">
                                    <HeaderCell className="text-unicon-lightBlue font-medium text-Hsm">
                                        Pool Name
                                    </HeaderCell>
                                    <HeaderCell className="text-unicon-lightBlue font-medium text-Hsm">
                                        No of Users
                                    </HeaderCell>
                                    <HeaderCell className="text-unicon-lightBlue font-medium text-Hsm">
                                        Token Ent. (Yearly )
                                    </HeaderCell>
                                    <HeaderCell className="text-unicon-lightBlue font-medium text-Hsm">
                                        Token Ent. (Monthly )
                                    </HeaderCell>
                                    <HeaderCell className="text-unicon-lightBlue font-medium text-Hsm">
                                        Token Usage
                                    </HeaderCell>
                                </HeaderRow>
                            </Header>

                            <Body>
                                {tableList.map((item) => (
                                    <Row item={item} key={item.id}>
                                        <Cell className="text-unicon-lightBlue font-medium text-base">{item.name}</Cell>
                                        <Cell className="text-unicon-lightBlue font-medium text-base">
                                            {item.no_of_users}
                                        </Cell>
                                        <Cell className="text-unicon-lightBlue font-medium text-base">
                                            <input
                                                value={item.Token_Ent_yearly}
                                                onChange={(e: any) => {
                                                    updateTokenEntitlement(id, 'tokenEntYearly', e.target.value);
                                                }}
                                            />
                                        </Cell>
                                        <Cell className="text-unicon-lightBlue font-medium text-base">
                                            <input
                                                value={item.Token_Ent_month}
                                                onChange={(e: any) => {
                                                    updateTokenEntitlement(id, 'tokenEntMonthly', e.target.value);
                                                }}
                                            />
                                        </Cell>
                                        <Cell className="text-unicon-lightBlue font-medium text-base">
                                            <ProgressBar value={item.token_usage} max={10000} />
                                        </Cell>
                                    </Row>
                                ))}
                            </Body>
                        </>
                    )}
                </Table>
            </div>
        </div>
    );
}
const Models1TableLayout = ModelsTable1;

export default Models1TableLayout;
