
import DashboardIcon from '../../assets/Sidebar/grid_view.svg';
import ModelsIcon from '../../assets/Sidebar/article.svg';
import PoolManagementIcon from '../../assets/Sidebar/manufacturing.svg';
import UserManagementIcon from '../../assets/Sidebar/manage_accounts.svg';
import ActivityLogIcon from '../../assets/Sidebar/query_stats.svg';
import LeftArrowIcon from '../../assets/Sidebar/LeftArrow.svg';
import RightArrowIcon from '../../assets/Sidebar/RightArrow.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import DashboardActive from '../../assets/Sidebar/dashboard-active.svg';
import ModelsActive from '../../assets/Sidebar/models-active.svg';
import PoolActive from '../../assets/Sidebar/pool-management-active.svg';
import UserActive from '../../assets/Sidebar/user-management-active.svg';
import ActivityActive from '../../assets/Sidebar/activity-log-active.svg';
import { useState } from 'react';
interface Tab {
    title: string;
    icon: string;
    activeIcon: string;
}

function Sidebar() {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [isCollapsed, setisCollapsed] = useState(false);
    const tabs: Tab[] = [
        { title: 'dashboard', icon: DashboardIcon, activeIcon: DashboardActive },
        { title: 'models', icon: ModelsIcon, activeIcon: ModelsActive },
        { title: 'pool management', icon: PoolManagementIcon, activeIcon: PoolActive },
        { title: 'user management', icon: UserManagementIcon, activeIcon: UserActive },
        { title: 'activity log', icon: ActivityLogIcon, activeIcon: ActivityActive },
    ];

    return (
        <div
            className={`${!isCollapsed ? ' w-[380px] 2xl:w-[380px] xl:w-[350px] lg:w-[320px] md:w-[300px]' : 'w-[70px]'} border border-[#EAECF0] relative  transition-width  min-h-screen `} style={{transition: 'width 0.3s ease'}}
        >
            <div className="flex items-center justify-start h-[85px]  pl-[15px] pr-[7px]">
                <span className='w-[34px] h-[34px]'>
                <i className="icon icon-unicon"></i>
                </span>
                <span
                    // className={`${isCollapsed && 'opacity-0 absolute'}  text-2xl text-unicon-lightBlue font-medium leading-7`}
                    className={`${isCollapsed && 'opacity-0 '} text-2xl text-unicon-lightBlue font-medium leading-7 w-[calc(100%-40px)] pl-3`}
                >
                    Chat<span className='font-bold'>Bolt</span>
                </span>
                
            </div>
            <div
                className={`${isCollapsed && 'bg-unicon-white'} bg-unicon-dashboardlistHover h-10  flex items-center justify-start pl-[18px] pr-[7px]`} style={{transition: 'background 0.3s ease'}}
            >
                <span className={`${isCollapsed && 'invisible '} font-medium text-base text-unicon-lightBlue visible capitalize whitespace-nowrap`} >Admin Panel</span>
            </div>
            {tabs.map((currentTab, index) => {
                const formattedTitle = currentTab.title.replace(/ /g, '-');
                const isActive = pathname.includes(formattedTitle);

                return (
                    <div
                        className={`bg-white font-medium text-xl flex capitalize leading-5 cursor-pointer h-14 items-center justify-start pl-[17px] pr-[7px] hover:bg-unicon-chatLiHover ${
                            isActive ? 'text-[#1D24CA]' : 'text-unicon-lightBlue'
                        }`}
                        key={index}
                        onClick={() => {
                            navigate(`/${formattedTitle}`);
                        }}
                        title={isCollapsed ? currentTab.title : ''}
                    >
                        <img
                            src={!isActive ? currentTab.icon : currentTab.activeIcon}
                            alt={`${currentTab.title} icon`}
                            className={`inline-block w-7 h-7`}
                        />
                     
                        <h2 className={`${isCollapsed && 'opacity-0 pointer-events-none'} whitespace-nowrap pl-[17px]`}>
                            {currentTab.title}
                        </h2>
                    </div>
                );
            })}
            {isCollapsed ? (
                <img
                    src={RightArrowIcon}
                    alt="rightarrow"
                    className="absolute right-0 translate-x-4 top-6 cursor-pointer"
                    onClick={() => {
                        setisCollapsed(false);
                    }}
                />
            ) : (
                <img
                    src={LeftArrowIcon}
                    alt="rightarrow"
                    className="absolute right-0 translate-x-4 top-6 cursor-pointer "
                    onClick={() => {
                        setisCollapsed(true);
                    }}
                />
            )}
        </div>
    );
}

export default Sidebar;
