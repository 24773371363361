import React, { useState } from 'react';
import { Table, Header, HeaderRow, Body, Row, HeaderCell, Cell } from '@table-library/react-table-library/table';
// import {HeaderCellSort, useSort} from'@table-library/react-table-library/sort';
import { useTheme } from '@table-library/react-table-library/theme';
interface Node {
    id: string;
    name: string;
    date_on: string;
    
}
interface Data {
    nodes: Node[];
}

const nodes: Node[] = [
    {
        id: '1',
        name: 'Climate change: Actions & Impact',
        date_on: '23Feb, 2024 | 10:15 AM',
    },
    {
        id: '2',
        name: 'Advantages of Artificial Intelligence',
        date_on: '23Feb, 2024 | 11:55 AM',
    },
];

function Archivedtable() {
    const data: Data = { nodes };
    const [ids, setIds] = useState<string[]>([]);

    const handleExpand = (item: Node) => {
        if (ids.includes(item.id)) {
            setIds(ids.filter((id) => id !== item.id));
        } else {
            setIds(ids.concat(item.id));
        }

       
    };

    const theme = useTheme({
        HeaderRow: `
            .th {
             border-bottom:1px solid #EAECF0;
             border-top:1px solid #EAECF0;
             padding:12px 24px;
             background-color: #F6F7FA;
             height:44px;
            }
          `,
        BaseCell: `
           &:first-of-type {
           border-left: 1px solid #EAECF0;
           >*{display:flex;}
           }
           &:last-of-type {
           border-right:1px solid #EAECF0;
           }
            &.icon-cell >div{
            display:inline-flex;
            align-item:center;
            justify-content:end;
           }
           border-bottom:1px solid #EAECF0;
           padding:12px 24px;
            height:60px;
          `,
   
    });

    return (
        <div>

            <Table data={data}  theme={theme} className="w-full rounded">
                {(tableList: Node[]) => (
                    <>
                        <Header className="">
                            <HeaderRow className="">
                                <HeaderCell className="text-unicon-lightBlue font-medium text-Hsm">Name</HeaderCell>
                                <HeaderCell className="text-unicon-lightBlue font-medium text-Hsm">Data</HeaderCell>
                                <HeaderCell className="text-unicon-lightBlue font-medium text-Hsm"></HeaderCell>

                            </HeaderRow>
                        </Header>

                        <Body>
                            {tableList.map((item) => (
                                <React.Fragment key={item.id}>
                                    <Row
                                        item={item}
                                        onClick={() => {
                                            handleExpand(item);
                                        }}
                                    >
                                       
                                        <Cell className="text-unicon-lightBlue font-medium text-base">{item.name}</Cell>
                                        <Cell className="text-unicon-lightBlue font-medium text-base "> {item.date_on.toString()} </Cell>
                                        <Cell className="text-unicon-lightBlue font-medium text-base icon-cell">
                                        <button className='w-[22px] h-[22px] flex items-center justify-center'><i className='icon icon-unarchive-grid'></i></button>
                                        <button className='w-[22px] h-[22px] flex items-center justify-center'><i className='icon icon-delete-grid'></i></button>
                                        </Cell>
                                    </Row>

                                   
                                </React.Fragment>
                            ))}
                        </Body>
                    </>
                )}
            </Table>

        </div>
    );
}
// const PoolWithLayout = withLayout(Pool);

export default Archivedtable;
