import React from 'react';
import Close from '../../assets/modal_cross.svg';
import Microsoft from '../../assets/microsoft.svg'
interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    title: string;
    subtitle: string;
    children: React.ReactNode;
    showIcon?: boolean;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, title, subtitle, children, showIcon }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="absolute inset-0 bg-black opacity-50" onClick={onClose} />
            <div className="bg-white rounded-xl w-[calc(100%-56rem)] h-[calc(100vh-10.6rem)]  z-10 " style={{boxShadow: '0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)'}}>
                <div className="flex w-full justify-between items-center rounded-t-xl border-b border-solid border-unicon-modalBorder h-[60px] px-[30px]">
                    <h2 className="flex items-center justify-start">
                    {showIcon && (
                            <span className='flex items-center justify-start w-[46px] h-[42px]'><img src={Microsoft} alt="model-icon" className='w-[42px] h-[42px]'/></span>
                        )}
                        <span className='font-semibold  text-xl text-unicon-lightBlue'>{title} <br/><span className='text-unicon-gray font-normal text-sm leading-[2px]'>{subtitle}</span></span></h2>
                    <img src={Close} alt="" onClick={onClose} className="cursor-pointer" />
                </div>
                <div className='w-full'>{children}</div>
            </div>
        </div>
    );
};

export default Modal;
