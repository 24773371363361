import { BaseService } from "./BaseService";

export class UsersService extends BaseService {
    public getUsersAsync = async (accessToken:string): Promise<any[]> => { 
        const result = await this.getResponseAsync<any[]>(
            {
                commandPath: 'users',
                method: 'GET',
            },
            accessToken,
        );

        return result;
    };

    public searchUsersAsync = async (accessToken:string,keyword:string): Promise<any[]> => { 
        const result = await this.getResponseAsync<any[]>(
            {
                commandPath: `/users/search?name=${keyword}`,
                method: 'GET',
            },
            accessToken,
        );

        return result;
    };
}
