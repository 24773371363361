// import profile from '../../../assets/Profile.svg';
// import edit from '../../../assets/Edit.svg';
// import zapIcon from '../../../assets/zap-icon.svg';
// import { ChatHistoryItem } from './ChatHistoryItem';

import { IChatMessage } from '../../../libs/models/ChatMessage';
import { ChatHistoryItem } from './ChatHistoryItem';

interface ChatHistoryProps {
    messages: IChatMessage[];
}

export const ChatHistory: React.FC<ChatHistoryProps> = ({ messages }) => {
    return (
        <div className="flex grow mx-2 mb-2 relative">
            <div className="  overflow-y-scroll flex flex-col items-start ">
                {messages?.map((message: IChatMessage, index: number) => (
                    <ChatHistoryItem key={message.timestamp} message={message} messageIndex={index} />
                ))}
                {/* {number.map((item: any, index: number) => (
                    <div key={index}>
                        <div className="flex items-center justify-between w-full">
                            <div className="flex gap-4 items-center">
                                <img src={profile} alt="profile" width={40} height={40} />
                                <h2 className="text-base font-semibold	text-[#1B2559]">
                                    The advantages of Artificial Intelligence
                                </h2>
                            </div>
                            <img src={edit} alt="edit-icon" />
                        </div>
                        <div className="flex items-center justify-between w-full mt-4">
                            <div className="flex gap-4 items-start">
                                <img src={zapIcon} alt="profile" width={40} height={40} />
                                <h2 className="text-sm font-medium text-[#1B2559]">
                                    Artificial Intelligence (AI) offers numerous advantages and has the potential to
                                    revolutionize various aspects of our lives. Here are some key advantages of AI:
                                    Automation: AI can automate repetitive and mundane tasks, saving time and effort for
                                    humans. It can handle large volumes of data, perform complex calculations, and
                                    execute tasks with precision and consistency. This automation leads to increased
                                    productivity and efficiency in various industries. Decision-making: AI systems can
                                    analyze vast amounts of data, identify patterns, and make informed decisions based
                                    on that analysis. This ability is particularly useful in complex scenarios where
                                    humans may struggle to process large datasets or where quick and accurate decisions
                                    are crucial. Improved accuracy: AI algorithms can achieve high levels of accuracy
                                    and precision in tasks such as image recognition, natural language processing, and
                                    data analysis. They can eliminate human errors caused by fatigue, distractions, or
                                    bias, leading to more reliable and consistent results. Continuous operation: AI
                                    systems can work tirelessly without the need for breaks, resulting in uninterrupted
                                    24/7 operations. This capability is especially beneficial in applications like
                                    customer support chatbots, manufacturing processes, and surveillance systems {item}.
                                </h2>
                            </div>
                        </div>
                    </div>
                ))} */}
            </div>
        </div>
    );
};
