import { ChatMessageType } from '../../../libs/models/ChatMessage';
import { useAppDispatch, useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { setSelectedConversation } from '../../../redux/features/conversations/conversationsSlice';
import { Conversations } from '../../../redux/features/conversations/ConversationsState';
// import optionsIcon from '../../../assets/options_icon.svg';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useChat } from '../../../libs/hooks';

interface IChatListSectionProps {
    header?: string;
    conversations: Conversations;
}

export const ChatListSection: React.FC<IChatListSectionProps> = ({ conversations }) => {
    const chat = useChat();
    const dispatch = useAppDispatch();
    const [optionsOpenOf, setoptionsOpenOf] = useState(-1);

    const { selectedId } = useAppSelector((state: RootState) => state.conversations);
    const keys = Object.keys(conversations);

    const onClick = (id: string) => {
        dispatch(setSelectedConversation(id));
    };

    const dropdownRef = useRef<HTMLDivElement | null>(null);

    const onClose = useCallback(() => {
        setoptionsOpenOf(-1);
    }, []);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                onClose();
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    function trimString(str: string, maxLength: number) {
        if (str.length > maxLength) {
            return str.substring(0, maxLength) + '...'; // Using substring
        }
        return str;
    }

    const onDeleteChat = (id: string) => {
        void chat.deleteChat(id);
        setoptionsOpenOf(-1);
    };

    const onArchiveChat = (id: string) => {
        void chat.archiveChat(id);
        setoptionsOpenOf(-1);
    };

    const onPinChat = (id: string) => {
        void chat.pinChat(id);
        setoptionsOpenOf(-1);
    };

    return keys.length > 0 ? (
        <ul className="mt-1">
            {keys.map((id, index) => {
                const convo = conversations[id];
                const messages = convo.messages;
                const lastMessage = messages[convo.messages.length - 1];
                const isSelected = id === selectedId;
                return (
                    <li
                        className={` relative w-full h-10 cursor-pointer  befpore:content-['*'] before:absolute before:left-0 before:w-[4px] before:h-full before:bg-unicon-chatBorderColor before:opacity-0  ${isSelected ? 'bg-unicon-chatLiHover before:opacity-100' : 'cursor-pointer'}`}
                        style={{ transition: 'all 0.2s ease' }}
                        key={index}
                        onClick={() => {
                            onClick(id);
                        }}
                    >
                        <div className="flex items-center justify-start h-10 pl-[18px] pr-[8px]">
                            <span className="w-8 h-7 pr-3">
                                <i className="icon chat-icon"></i>
                            </span>
                            <span className="text-unicon-lightBlue  font-medium text-base mr-[5px] w-[257px] text-ellipsis whitespace-nowrap overflow-hidden 2xl:w-[257px] xl:w-[228px] lg:w-[196px]">
                                {messages.length > 0
                                    ? lastMessage.type === ChatMessageType.Document
                                        ? 'Sent a file'
                                        : lastMessage.type === ChatMessageType.Plan
                                          ? 'Click to view proposed plan'
                                          : trimString(lastMessage.content, 50)
                                    : 'Click to start the chat'}
                            </span>
                            <span className="inline-flex items-center justify-end">
                                <button className="share-button w-7 h-7 rounded-md flex items-center justify-center hover:bg-unicon-iconHover">
                                    <i className="icon icon-share"></i>
                                </button>
                                <button
                                    className="more-button w-7 h-7 rounded-md flex items-center justify-center hover:bg-unicon-iconHover"
                                    onClick={() => {
                                        setoptionsOpenOf(index);
                                    }}
                                >
                                    <i className="icon icon-more"></i>
                                </button>
                            </span>
                        </div>
                        {optionsOpenOf == index && (
                            <div
                                ref={dropdownRef}
                                className="w-[187px]  bg-white rounded-lg absolute right-[-167px] top-[65%] flex flex-col z-50"
                                style={{ boxShadow: '14px 17px 40px 4px #7090B02E' }}
                            >
                                <ul className="py-2 px-3">
                                    <li
                                        className="hover:bg-blue-100  h-10 cursor-pointer flex items-center justify-start"
                                        onClick={() => {
                                            onPinChat(id);
                                        }}
                                    >
                                        <i className="icon icon-pin-chartlist"></i>
                                        <span className="text-unicon-moreFontColor text-base font-medium leading-4 pl-2">
                                            Pin
                                        </span>
                                    </li>
                                    <li className="hover:bg-blue-100 flex items-center justify-start h-10 cursor-pointer">
                                        <i className="icon icon-rename-chartlist"></i>
                                        <span className="text-unicon-moreFontColor text-base font-medium leading-4 pl-2">
                                            Rename
                                        </span>
                                    </li>
                                    <li
                                        className="hover:bg-blue-100 flex items-center justify-start h-10 cursor-pointer"
                                        onClick={() => {
                                            onArchiveChat(id);
                                        }}
                                    >
                                        <i className="icon icon-archive-chartlist"></i>
                                        <span className="text-unicon-moreFontColor text-base font-medium leading-4 pl-2">
                                            Archive
                                        </span>
                                    </li>
                                    <li
                                        className="hover:bg-blue-100 flex items-center justify-start h-10 cursor-pointer"
                                        onClick={() => {
                                            onDeleteChat(id);
                                        }}
                                    >
                                        <i className="icon icon-delete-chartlist"></i>
                                        <span className="text-unicon-moreFontColor text-base font-medium leading-4 pl-2">
                                            Delete
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        )}
                    </li>
                );
            })}
        </ul>
    ) : null;
};
