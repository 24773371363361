import { BaseService } from "./BaseService";

export class GroupsService extends BaseService {
    public searchGroupsAsync = async (accessToken:string,keyword:string): Promise<any[]> => { 
        const result = await this.getResponseAsync<any[]>(
            {
                commandPath: `/group/search?groupname=${keyword}`,
                method: 'GET',
            },
            accessToken,
        );

        return result;
    };
}
