export function getInitials(name: string | undefined) {
    if (name) {
        const initials = name
            .split(' ')
            .map((word) => word[0])
            .join('');
        return initials.toUpperCase();
    } else {
        return 'UU';
    }
}

export const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return `flex items-center justify-center w-[40px] h-[40px] rounded-full bg-[${color}] text-white text-xl font-semibold`;
  };