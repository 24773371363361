import React from 'react';
import { useChat } from '../../../libs/hooks/useChat';
import { AuthorRoles, ChatMessageType, IChatMessage } from '../../../libs/models/ChatMessage';
import { useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { DefaultChatUser } from '../../../redux/features/app/AppState';
import { PlanViewer } from '../plan-viewer/PlanViewer';
import { TypingIndicator } from '../typing-indicator/TypingIndicator';
import * as utils from './../../utils/TextUtils';
import { ChatHistoryDocumentContent } from './ChatHistoryDocumentContent';
import { ChatHistoryTextContent } from './ChatHistoryTextContent';
import zapIcon from '../../../assets/zap-icon.svg';
import { getInitials } from '../../utils/Helper';

interface ChatHistoryItemProps {
    message: IChatMessage;
    messageIndex: number;
}

export const ChatHistoryItem: React.FC<ChatHistoryItemProps> = ({ message, messageIndex }) => {
    const chat = useChat();

    const { conversations, selectedId } = useAppSelector((state: RootState) => state.conversations);
    const { activeUserInfo } = useAppSelector((state: RootState) => state.app);

    const isDefaultUser = message.userId === DefaultChatUser.id;
    const isMe = isDefaultUser || (message.authorRole === AuthorRoles.User && message.userId === activeUserInfo?.id);
    const isBot = message.authorRole === AuthorRoles.Bot;
    const user = isDefaultUser
        ? DefaultChatUser
        : chat.getChatUserById(message.userName, selectedId, conversations[selectedId].users);
    const fullName = user?.fullName ?? message.userName;

    let content: JSX.Element;
    if (isBot && message.type === ChatMessageType.Plan) {
        content = <PlanViewer message={message} messageIndex={messageIndex} />;
    } else if (message.type === ChatMessageType.Document) {
        content = <ChatHistoryDocumentContent isMe={isMe} message={message} />;
    } else {
        content =
            isBot && message.content.length === 0 ? <TypingIndicator /> : <ChatHistoryTextContent message={message} />;
    }

    return (
        <div
            className="flex items-center justify-between w-full px-[50px] 2xl:px-[235px] xl:px-[166px] lg:px-[100px] md:px-[80px]"
            data-testid={`chat-history-item-${messageIndex}`}
            data-username={fullName}
            data-content={utils.formatChatTextContent(message.content)}
        >
            {isBot ? (
                <div className="flex gap-4 items-start mt-4 pb-[14px]">
                    <img src={zapIcon} alt="profile" width={40} height={40} />
                    <h2 className="text-base font-medium text-unicon-lightBlue leading-6 w-[(100%-45px)]"> {content}</h2>
                </div>
            ) : (
                <div className="flex gap-4 items-start mt-4 pb-[14px]">
                    <div className="flex items-center justify-center w-[40px] h-[40px] rounded-full bg-unicon-gray text-white text-xl font-semibold">
                        {getInitials(fullName)}
                    </div>
                    <h2 className="text-xl font-semibold text-unicon-lightBlue w-[(100%-45px)] leading-6"> {content}</h2>
                </div>
            )}
        </div>
    );
};
