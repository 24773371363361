import React, { useCallback, useEffect, useState } from 'react';
import withLayout from '../dashboard/WithLayout';
import { Table, Header, HeaderRow, Body, Row, HeaderCell, Cell } from '@table-library/react-table-library/table';
import { useTheme } from '@table-library/react-table-library/theme';
import ProgressBar from '../utils/ProgressBar';
import Modal from '../utils/Modal';
import Microsoft from '../../assets/microsoft.svg';
import modelPlaceholder from '../../assets/model_placeholder.svg';
import Avatar from '../../assets/Avatar.svg';
import Models1TableLayout from './model1';
import { PoolService } from '../../libs/services/PoolService';
import { useMsal } from '@azure/msal-react';
import { AuthHelper } from '../../libs/auth/AuthHelper';
import { getErrorDetails } from '../utils/TextUtils';
import { AlertType } from '../../libs/models/AlertType';
import { addAlert } from '../../redux/features/app/appSlice';
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks';
import { UsersService } from '../../libs/services/UsersService';
import { getInitials } from '../utils/Helper';
import { ModelService } from '../../libs/services/ModelService';
import { RootState } from '../../redux/app/store';
import { toast } from 'react-toastify';
import LoadingSpinner from '../../hooks/Loader';
import debounce from 'lodash.debounce';
import { GroupsService } from '../../libs/services/GroupsService';

interface Node {
    id: string;
    name: string;
    no_of_users: number;
    assigned_on: string;
    provider: string;
    model_name: string;
    version: number;
    entitled_tokens: number;
    token_usage: number;
}
interface Data {
    nodes: Node[];
}

const nodes: Node[] = [
    {
        id: '1',
        name: 'Pool Name 1',
        no_of_users: 13,
        assigned_on: 'Feb 2024',
        provider: 'Microsoft',
        model_name: 'Model 1',
        version: 3.5,
        entitled_tokens: 1000,
        token_usage: 50,
    },
    {
        id: '2',
        name: 'Pool Name 2',
        no_of_users: 13,
        assigned_on: 'Feb 2024',
        provider: 'Azure Open AI',
        model_name: 'Model 2',
        version: 3.5,
        entitled_tokens: 1000,
        token_usage: 150,
    },
    {
        id: '3',
        name: 'Pool Name 3',
        no_of_users: 8,
        assigned_on: 'Feb 2024',
        provider: 'Meta',
        model_name: 'Model 3',
        version: 1.2,
        entitled_tokens: 300,
        token_usage: 250,
    },
];

function Pool() {
    const data: Data = { nodes };
    const [ids, setIds] = useState<string[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [openIndex, setOpenIndex] = useState<number | null>(null);
    const [activeTab, setActiveTab] = useState(0);

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const handleExpand = (item: Node) => {
        if (ids.includes(item.id)) {
            setIds(ids.filter((id) => id !== item.id));
        } else {
            setIds(ids.concat(item.id));
        }
    };

    const toggleAccordion = (index: number) => {
        setOpenIndex(openIndex === index ? null : index); // Toggle between open and closed
    };

    const subTexts = ['Chat completion', 'Chat completion', 'Embeddings'];

    const [filterItems, setFilterItems] = useState([
        { id: 1, label: 'model 3' },
        { id: 2, label: 'model 4' },
    ]);
    const handleRemoveItem = (id: number) => {
        setFilterItems(filterItems.filter((item) => item.id !== id));
    };

    const theme = useTheme({
        HeaderRow: `
        .th {
         border-bottom:1px solid #EAECF0;
         padding:12px 24px;
         background-color: #F6F7FA;
         height:44px;
        }
      `,
        BaseCell: `
       &:first-of-type {
       >*{display:flex;}
       }
       
        &.icon-cell >div{
        display:inline-flex;
        align-item:center;
        justify-content:end;
       }
       border-bottom:1px solid #EAECF0;
       padding:12px 24px;
       height:60px;
      `,
    });

    //creating a pool
    const dispatch = useAppDispatch();
    const poolService = new PoolService();
    const usersService = new UsersService();
    const groupsService = new GroupsService();
    const modelService = new ModelService();

    const { instance, inProgress } = useMsal();

    const [poolName, setPoolName] = useState('');
    const [description, setDescription] = useState('');
    const [usersList, setusersList] = useState<any[]>([]);
    const [groupsList, setgroupsList] = useState<any>([]);
    const [selectedUsers, setselectedUsers] = useState<any[]>([]);
    const [selectedGroups, setselectedGroups] = useState<any>([]);
    const [selectedModels, setselectedModels] = useState<any[]>([]);
    const [modelsList, setmodelsList] = useState<any>([]);
    const [poolsList, setPoolsList] = useState<any>([]);
    const [poolsDataForTable, setPoolsDataForTable] = useState<any>([]);

    const getModelsList = async () => {
        modelService.getModelsAsync(await AuthHelper.getSKaaSAccessToken(instance, inProgress)).then((response) => {
            setmodelsList(response);
        });
    };

    const getPoolsList = async () => {
        poolService.getPoolsAsync(await AuthHelper.getSKaaSAccessToken(instance, inProgress)).then((response) => {
            setPoolsList(response);
        });
    };

    useEffect(() => {
        getPoolsList();
        getModelsList();
    }, []);

    const toggleUser = (user) => {
        setselectedUsers((prevUsers) => {
            const userExists = prevUsers.some((existingUser) => existingUser.id === user.id);
            if (userExists) {
                return prevUsers.filter((existingUser) => existingUser.id !== user.id);
            } else {
                return [...prevUsers, user];
            }
        });
    };

    const toggleGroup = (group) => {
        setselectedGroups((prevGroups) => {
            const groupExists = prevGroups.some((existingGroup) => existingGroup.id === group.id);
            if (groupExists) {
                return prevGroups.filter((existingGroup) => existingGroup.id !== group.id);
            } else {
                return [...prevGroups, group];
            }
        });
    };

    const toggleModels = (model) => {
        setselectedModels((prevModels) => {
            const userExists = prevModels.some((existingModels) => existingModels.id === model.id);
            if (userExists) {
                return prevModels.filter((existingModels) => existingModels.id !== model.id);
            } else {
                return [...prevModels, model];
            }
        });
    };

    const [tokenEntitlements, setTokenEntitlements] = useState<any>({});

    useEffect(() => {
        const tempData: any = { nodes: [] };
        poolsList.forEach((pool) => {
            const date = new Date(pool.createdOn);
            const formattedDate = date.toLocaleString('en-US', {
                month: 'short',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: true,
            });
            tempData.nodes.push({
                id: pool.id,
                name: pool.poolName,
                no_of_users: pool.users.length,
                assigned_on: formattedDate,
                provider: 'Microsoft',
                model_name: 'gpt-35-turbo',
                version: 3.5,
                entitled_tokens: 1000,
                token_usage: 50,
            });
        });
        setPoolsDataForTable(tempData);
    }, [poolsList]);

    useEffect(() => {
        const initialTokenEntitlements = selectedModels.reduce((acc, model) => {
            acc[model.id] = { tokenEntMonthly: 0, tokenEntYearly: 0 };
            return acc;
        }, {});

        setTokenEntitlements(initialTokenEntitlements);
    }, [selectedModels]);

    const updateTokenEntitlement = (modelId: any, field: any, value: number) => {
        Number(value);
        setTokenEntitlements((prevState) => ({
            ...prevState,
            [modelId]: {
                ...prevState[modelId],
                [field]: value,
            },
        }));
    };
    const [modelEntitlements, setmodelEntitlements] = useState<any>([]);
    const { activeUserInfo } = useAppSelector((state: RootState) => state.app);

    useEffect(() => {
        const tempArray: any = [];
        selectedModels.map((model) => {
            tempArray.push({
                id: '',
                modelId: model.id,
                poolId: '',
                entitledToken: tokenEntitlements[model.id]?.tokenEntYearly.toString(),
                tokenUsage: 0,
                createdOn: new Date().toISOString(),
                createdBy: activeUserInfo?.username,
                status: true,
            });
        });
        setmodelEntitlements(tempArray);
    }, [tokenEntitlements]);

    const createPool = async () => {
        if (poolName == '' || description == '' || selectedUsers.length == 0 || selectedModels.length == 0) {
            toast.error('Please ensure that all required fields are filled out correctly.');
            return;
        }
        try {
            await poolService
                .createPoolAsync(
                    poolName,
                    description,
                    modelEntitlements,
                    selectedUsers,
                    [],
                    activeUserInfo?.username,
                    true,
                    await AuthHelper.getSKaaSAccessToken(instance, inProgress),
                )
                .then((result: any) => {
                    toast.success('Pool Created');
                    setPoolName('');
                    setDescription('');
                    setselectedUsers([]);
                    setselectedModels([]);
                    setTokenEntitlements({});
                    setIsModalOpen(false);
                });
        } catch (e: any) {
            const errorMessage = `Unable to create new Pool. Details: ${getErrorDetails(e)}`;
            dispatch(addAlert({ message: errorMessage, type: AlertType.Error }));
        }
    };

    const [keyword, setKeyword] = useState('');

    const [currentSearchTab, setcurrentSearchTab] = useState(0);
    const [loading, setloading] = useState(false);

    const debouncedSearch = useCallback(
        debounce(async (keyword: string) => {
            setloading(true);
            if (keyword.trim() === '') {
                setusersList([]);
                setgroupsList([]);
                setloading(false);
                return;
            }
            try {
                if (currentSearchTab === 0) {
                    const result = await usersService.searchUsersAsync(
                        await AuthHelper.getSKaaSAccessToken(instance, inProgress),
                        keyword,
                    );
                    setloading(false);
                    setusersList(result);
                } else {
                    const result = await groupsService.searchGroupsAsync(
                        await AuthHelper.getSKaaSAccessToken(instance, inProgress),
                        keyword,
                    );
                    setloading(false);
                    setgroupsList(result);
                }
            } catch (error) {
                console.error('Error searching for users:', error);
            }
        }, 500),
        [instance, inProgress, currentSearchTab],
    );

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newKeyword = event.target.value;
        setKeyword(newKeyword);
        debouncedSearch(newKeyword);
    };


    return (
        <div>
            <div className="flex justify-between items-center h-[60px]">
                <div className="inputwrap h-10 flex items-center justify-start relative">
                    <i className="icon icon-input-search absolute left-0 cursor-pointer"></i>
                    <input
                        className="min-w-[332px] h-[32px] border-b border-[#D0D5DD] border-solid focus-visible:outline-none pl-[26px] text-sm text-unicon-lightBlue font-medium"
                        type="text"
                        placeholder="Search users/roles.."
                    />
                    <button
                        className={`w-10 h-10 flex items-center justify-center relative  ${filterItems.length >= 1 ? 'filter-active-icon' : ''} `}
                        style={{ transition: 'all 0.3s ease' }}
                    >
                        <i className="icon icon-filter"></i>
                    </button>
                </div>
                <div className="h-10 flex items-center justify-end">
                    <button
                        className="bg-[#387ADF] w-[117px] h-[36px] text-white rounded-lg text-base font-semibold flex items-center justify-center gap-[5px] "
                        onClick={toggleModal}
                    >
                        Add New <i className="icon icon-addnew"></i>
                    </button>
                    <button className="w-11 h-6 flex items-center justify-end ">
                        <i className="icon icon-excel"></i>
                    </button>
                </div>
            </div>
            <div
                className="border border-solid border-unicon-grayBorder rounded h-[calc(100vh-195px)]"
                style={{ boxShadow: '0px 1px 2px 0px #1018280F, 0px 1px 3px 0px #1018281A' }}
            >
                <div
                    className={`filter-list-wrpa flex justify-start py-3 px-6 min-h-[56px] ${filterItems.length === 0 ? 'hidden' : ''}`}
                    style={{ transition: 'all 0.3s ease' }}
                >
                    <span className="font-semibold text-base text-unicon-gray w-[65px] m-auto">Filters :</span>
                    <ul className="scroll-div inline-flex justify-start overflow-x-hidden overflow-y-auto gap-3 max-h-[100px] flex-wrap w-[calc(100%-65px)]">
                        {filterItems.map((item) => (
                            <li
                                key={item.id}
                                className="flex items-center justify-between h-7 rounded-2xl gap-[0.5rem] py-4 pl-3 pr-2 bg-unicon-chatLiHover"
                            >
                                <span className="font-medium text-base text-unicon-blue min-w-[58px] max-w-[130px] truncate">
                                    {item.label}
                                </span>
                                <i
                                    className="icon icon-close-pills cursor-pointer"
                                    onClick={() => {
                                        handleRemoveItem(item.id);
                                    }}
                                ></i>
                            </li>
                        ))}
                    </ul>
                </div>
                {poolsDataForTable?.nodes?.length > 0 ? (
                    <Table data={poolsDataForTable} theme={theme} className="w-full rounded max-h-[calc(100vh-268px)] scroll-div scroll-smooth scroll-p-0">
                        {(tableList: Node[]) => (
                            <>
                                <Header>
                                    <HeaderRow>
                                        <HeaderCell className="text-unicon-lightBlue font-medium text-Hsm" title="Name">
                                            Name
                                        </HeaderCell>
                                        <HeaderCell
                                            className="text-unicon-lightBlue font-medium text-Hsm"
                                            title="No of users"
                                        >
                                            No of users
                                        </HeaderCell>
                                        <HeaderCell
                                            className="text-unicon-lightBlue font-medium text-Hsm"
                                            title="Assigned On"
                                        >
                                            Assigned On
                                        </HeaderCell>
                                        <HeaderCell
                                            className="text-unicon-lightBlue font-medium text-Hsm"
                                            title="Provider"
                                        >
                                            Provider
                                        </HeaderCell>
                                        <HeaderCell
                                            className="text-unicon-lightBlue font-medium text-Hsm"
                                            title="Model Name"
                                        >
                                            Model Name
                                        </HeaderCell>
                                        <HeaderCell
                                            className="text-unicon-lightBlue font-medium text-Hsm"
                                            title="Version"
                                        >
                                            Version
                                        </HeaderCell>
                                        <HeaderCell
                                            className="text-unicon-lightBlue font-medium text-Hsm"
                                            title="Entitled Tokens"
                                        >
                                            Entitled Tokens
                                        </HeaderCell>
                                        <HeaderCell
                                            className="text-unicon-lightBlue font-medium text-Hsm"
                                            title="Token Usage"
                                        >
                                            Token Usage
                                        </HeaderCell>
                                    </HeaderRow>
                                </Header>

                                <Body>
                                    {tableList.map((item) => (
                                        <React.Fragment key={item.id}>
                                            <Row item={item}>
                                                <Cell
                                                    className="text-unicon-lightBlue font-medium text-base"
                                                    title={item.name.toString()}
                                                >
                                                    <div
                                                        className="flex items-center justify-start"
                                                        onClick={() => {
                                                            handleExpand(item);
                                                        }}
                                                    >
                                                        {ids.includes(item.id) ? (
                                                            <button className="flex items-center justify-center w-[30px] h-[30px]">
                                                                <i className="icon icon-arrow-right up"></i>
                                                            </button>
                                                        ) : (
                                                            <button className="flex items-center justify-center w-[30px] h-[30px]">
                                                                <i className="icon icon-arrow-right"></i>
                                                            </button>
                                                        )}
                                                        <h1 className="text-unicon-lightBlue font-medium text-base flex items-center justify-start">
                                                            {item.name}
                                                            <i className="icon icon-tooltip "></i>
                                                        </h1>
                                                    </div>
                                                </Cell>
                                                <Cell
                                                    className="text-unicon-lightBlue font-medium text-base"
                                                    title={item.no_of_users.toString()}
                                                >
                                                    {item.no_of_users}
                                                </Cell>
                                                <Cell className="text-unicon-lightBlue font-medium text-base"></Cell>
                                                <Cell className="text-unicon-lightBlue font-medium text-base"></Cell>
                                                <Cell className="text-unicon-lightBlue font-medium text-base"></Cell>
                                                <Cell className="text-unicon-lightBlue font-medium text-base"></Cell>
                                                <Cell className="text-unicon-lightBlue font-medium text-base"></Cell>
                                                <Cell className="text-unicon-lightBlue font-medium text-base"></Cell>
                                            </Row>

                                            {!ids.includes(item.id) && (
                                                <Row item={item}>
                                                    <Cell className="text-unicon-lightBlue font-medium text-base"></Cell>
                                                    <Cell className="text-unicon-lightBlue font-medium text-base"></Cell>
                                                    <Cell
                                                        className="text-unicon-lightBlue font-medium text-base"
                                                        title={item.assigned_on.toString()}
                                                    >
                                                        {' '}
                                                        {item.assigned_on.toString()}
                                                    </Cell>
                                                    <Cell
                                                        className="text-unicon-lightBlue font-medium text-base"
                                                        title={item.provider.toString()}
                                                    >
                                                        {item.provider}
                                                    </Cell>
                                                    <Cell
                                                        className="text-unicon-lightBlue font-medium text-base"
                                                        title={item.model_name.toString()}
                                                    >
                                                        <div className="flex items-center justify-start cursor-pointer">
                                                            <img
                                                                src={Microsoft}
                                                                alt="microsoft"
                                                                className="w-[22px] h-[22px] pr-[3px]"
                                                            />
                                                            {item.model_name}
                                                        </div>
                                                    </Cell>
                                                    <Cell
                                                        className="text-unicon-lightBlue font-medium text-base"
                                                        title={item.version.toString()}
                                                    >
                                                        {item.version}
                                                    </Cell>
                                                    <Cell
                                                        className="text-unicon-lightBlue font-medium text-base"
                                                        title={item.entitled_tokens.toString()}
                                                    >
                                                        {item.entitled_tokens}
                                                    </Cell>
                                                    <Cell className="text-unicon-lightBlue font-medium text-base">
                                                        <ProgressBar value={50} max={100} />
                                                    </Cell>
                                                </Row>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </Body>
                            </>
                        )}
                    </Table>
                ) : (
                    <LoadingSpinner />
                )}
            </div>
            <Modal isOpen={isModalOpen} onClose={toggleModal} showIcon={false} title="Add New" subtitle="">
                <div className="modalbody w-full relative h-[calc(100vh-14.3rem)]">
                    <div className="modal-content-wrap h-[calc(100vh-19.3rem)] bg-unicon-activityhover ">
                        <div className="form-group-row  flex flex-col gap-5 py-[25px] px-[30px] bg-unicon-white">
                            <div className="form-group-col ">
                                <label className="block font-semibold text-base text-unicon-lightBlue mb-[6px]">
                                    Pool Name
                                </label>
                                <input
                                    className="min-w-[451px]  font-normal text-base text-unicon-lightBlue border border-unicon-inputBorder h-11 rounded-lg flex items-center justify-start px-[14px] bg-unicon-white focus-visible:outline-none"
                                    style={{ boxShadow: '0px 1px 2px 0px #1018280D' }}
                                    type="text"
                                    placeholder="Enter First Name"
                                    value={poolName}
                                    onChange={(e) => {
                                        setPoolName(e.target.value);
                                    }}
                                ></input>
                            </div>
                            {openIndex == null && (
                                <div className="form-group-col ">
                                    <label className="block font-medium text-base text-unicon-lightBlue mb-[6px]">
                                        Description
                                    </label>
                                    <textarea
                                        className="min-w-[451px]  w-[100%] h-[111px] font-normal text-base text-unicon-lightBlue border border-unicon-inputBorder rounded-lg flex items-center justify-start px-[14px] py-[10px] bg-unicon-white focus-visible:outline-none"
                                        style={{ boxShadow: '0px 1px 2px 0px #1018280D' }}
                                        name="description"
                                        placeholder="Enter Description"
                                        value={description}
                                        onChange={(e) => {
                                            setDescription(e.target.value);
                                        }}
                                    ></textarea>
                                </div>
                            )}
                        </div>

                        {/* acoordion */}
                        <div className="bg-unicon-activityhover relative w-full  py-[25px] px-[30px] border-t border-solid border-unicon-modalBorder h-auto">
                            <button
                                className="membership-expand w-6 h-6 rounded-full bg-unicon-white border border-solid border-unicon-modalBorder"
                                style={{ boxShadow: '0px 1px 2px 0px #1018280D' }}
                            >
                                <i className="icon icon-pool-membership"></i>
                            </button>
                            <h3 className="font-medium text-xl text-unicon-lightBlue pb-4">
                                Membership & Model Settings
                            </h3>
                            <div className="scroll-div min-h-[244px] max-h-[400px] overflow-hidden overflow-y-auto">
                                <div
                                    className="accordion-list border border-solid border-unicon-modalBorder rounded  mb-5"
                                    style={{ boxShadow: '0px 1px 2px 0px #1018280D' }}
                                >
                                    <div
                                        className="py-[10px] px-[12px] flex justify-between items-center cursor-pointer border-b border-solid border-unicon-modalBorder bg-unicon-white rounded-t"
                                        onClick={() => {
                                            toggleAccordion(0);
                                        }}
                                    >
                                        <h4 className="font-medium text-base text-unicon-lightBlue inline-flex items-center justify-start">
                                            <span className="w-5 h-5 rounded-full flex items-center justify-center text-unicon-white bg-unicon-accordionNum text-[11px] font-extrabold mr-2">
                                                1
                                            </span>
                                            Assign Member/ AD Group
                                        </h4>
                                        <span className="icon icon-collapsed">{openIndex === 0}</span>
                                    </div>
                                    {openIndex === 0 && (
                                        <div className="py-[10px] px-[16px]  bg-unicon-white rounded-b">
                                            <div className="flex  justify-between gap-4">
                                                {/* right */}
                                                <div className="w-1/2  pr-[30px]">
                                                    <p className="text-sm text-unicon-gray font-normal pt-[10px]">
                                                        Select users/ AD groups to add in the new pool.
                                                    </p>
                                                    <p className="text-sm text-unicon-gray font-normal pt-[10px]">
                                                        Lorem ipsum dolor sit amet consectetur. Id mauris ut rhoncus dui
                                                        cursus lectus donec tellus dui. Mauris gravida bibendum nisl
                                                        aenean vel.
                                                    </p>
                                                    <div className="border-t border-solid border-unicon-modalBorder mt-[20px]">
                                                        <h4 className="w-full font-medium text-base text-unicon-lightBlue py-[12px]">
                                                            Selection
                                                        </h4>
                                                        <ul className="scroll-div inline-flex justify-start overflow-x-hidden overflow-y-auto gap-3 max-h-[150px] w-full flex-wrap ">
                                                            {selectedUsers.map((user) => {
                                                                return (
                                                                    <li
                                                                        key={user.id}
                                                                        className="flex items-center justify-between h-7 rounded-2xl gap-[0.5rem] truncate py-4 pl-3 pr-2 bg-unicon-chatLiHover"
                                                                    >
                                                                        <span className="font-medium text-base text-unicon-blue min-w-[58px] max-w-[130px]">
                                                                            {user.userName}
                                                                        </span>
                                                                        <i
                                                                            className="icon icon-close-pills cursor-pointer"
                                                                            onClick={() => {
                                                                                toggleUser(user);
                                                                            }}
                                                                        ></i>
                                                                    </li>
                                                                );
                                                            })}
                                                            {selectedGroups.map((group) => {
                                                                return (
                                                                    <li
                                                                        key={group.id}
                                                                        className="flex items-center justify-between h-7 rounded-2xl gap-[0.5rem] truncate py-4 pl-3 pr-2 bg-unicon-chatLiHover"
                                                                    >
                                                                        <span className="font-medium text-base text-unicon-blue min-w-[58px] max-w-[130px]">
                                                                            {group.displayName}
                                                                        </span>
                                                                        <i
                                                                            className="icon icon-close-pills cursor-pointer"
                                                                            onClick={() => {
                                                                                toggleGroup(group);
                                                                            }}
                                                                        ></i>
                                                                    </li>
                                                                );
                                                            })}
                                                        </ul>
                                                    </div>
                                                </div>

                                                {/* right */}
                                                <div className="w-1/2 pr-[5px]">
                                                    <div className="typeHeadWrapPool relative border border-solid border-unicon-modalBorder rounded-lg h-11 w-full bg-white py-[10px] px-4">
                                                        <input
                                                            type="text"
                                                            className="font-medium text-base text-unicon-lightBlue w-full pl-[30px] focus-visible:outline-none"
                                                            value={keyword}
                                                            onChange={handleInputChange}
                                                        />
                                                    </div>
                                                    <div
                                                        className="filter-typeHeadWrap rounded-lg pt-[5px] pb-[5px] mt-[2px] border border-solid border-unicon-modalBorder"
                                                        style={{ boxShadow: '0px 1px 2px 0px #1018280D' }}
                                                    >
                                                        <span className="font-normal text-sm text-unicon-gray pl-[10px]">
                                                            Filters
                                                        </span>
                                                        <ul className="gap-3 flex items-center justify-start px-[12px] py-[5px]">
                                                            <li
                                                                className={`flex items-center justify-center h-7 rounded-2xl border border-solid border-unicon-modalBorder text-Hsm font-medium text-unicon-lightBlue py-1 px-4 cursor-pointer ${currentSearchTab == 0 && 'active-filter-pill'}`}
                                                                style={{ boxShadow: '0px 1px 2px 0px #1018280D' }}
                                                                onClick={() => {
                                                                    setcurrentSearchTab(0);
                                                                }}
                                                            >
                                                                User
                                                            </li>

                                                            <li
                                                                className={`flex items-center justify-center h-7 rounded-2xl border border-solid border-unicon-modalBorder text-Hsm font-medium text-unicon-lightBlue py-1 px-4 cursor-pointer ${currentSearchTab == 1 && 'active-filter-pill'}`}
                                                                style={{ boxShadow: '0px 1px 2px 0px #1018280D' }}
                                                                onClick={() => {
                                                                    setcurrentSearchTab(1);
                                                                }}
                                                            >
                                                                AD Group
                                                            </li>
                                                        </ul>
                                                        <ul className="scroll-div w-full SelecteFilterList-wrap pt-[2px] pb-[5px] max-h-[152px] overflow-hidden overflow-y-auto">
                                                            {loading ? (
                                                                <LoadingSpinner width="24px" height="24px" />
                                                            ) : currentSearchTab == 0 ? (
                                                                usersList.map((user, index) => (
                                                                    <li
                                                                        key={index}
                                                                        className="flex items-center justify-between h-15 px-5 py-[10px] w-full"
                                                                        onClick={() => {
                                                                            toggleUser(user);
                                                                        }}
                                                                    >
                                                                        <div className="flex items-center justify-start">
                                                                            <div className="flex items-center justify-center w-[40px] h-[40px] rounded-full bg-unicon-gray text-white text-xl font-semibold">
                                                                                {getInitials(user.userName)}
                                                                            </div>
                                                                            <div
                                                                                className="font-medium text-unicon-lightBlue text-base leading-[18px] pl-[10px]"
                                                                                title={user.userName}
                                                                            >
                                                                                {user.userName}
                                                                                <br />
                                                                                <span
                                                                                    className="text-unicon-gray text-sm leading-none"
                                                                                    title={`${user.userName.toLowerCase().replace(' ', '')}@mail.com`}
                                                                                >
                                                                                    {`${user.userName.toLowerCase().replace(' ', '')}@mail.com`}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <button className="w-6 h-6 flex items-center justify-center">
                                                                            <i
                                                                                className={`icon ${selectedUsers.some((existingUser) => existingUser.id === user.id) ? 'icon-tick' : 'icon-plus-filter'}`}
                                                                            ></i>
                                                                        </button>
                                                                    </li>
                                                                ))
                                                            ) : (
                                                                groupsList.map((group, index) => (
                                                                    <li
                                                                        key={index}
                                                                        className="flex items-center justify-between h-15 px-5 py-[10px] w-full"
                                                                        onClick={() => {
                                                                            toggleGroup(group);
                                                                        }}
                                                                    >
                                                                        <div className="flex items-center justify-start">
                                                                            <div className="flex items-center justify-center w-[40px] h-[40px] rounded-full bg-unicon-gray text-white text-xl font-semibold">
                                                                                {getInitials(group.displayName)}
                                                                            </div>
                                                                            <div
                                                                                className="font-medium text-unicon-lightBlue text-base leading-[18px] pl-[10px]"
                                                                                title={group.displayName}
                                                                            >
                                                                                {group.displayName}
                                                                                <br />
                                                                                {/* <span
                                                                                      className="text-unicon-gray text-sm leading-none"
                                                                                      title={`${group.displayName.toLowerCase().replace(' ', '')}@mail.com`}
                                                                                  >
                                                                                      {`${group.displayName.toLowerCase().replace(' ', '')}@mail.com`}
                                                                                  </span> */}
                                                                            </div>
                                                                        </div>
                                                                        <button className="w-6 h-6 flex items-center justify-center">
                                                                            <i
                                                                                className={`icon ${selectedGroups.some((existingGroups) => existingGroups.id === group.id) ? 'icon-tick' : 'icon-plus-filter'}`}
                                                                            ></i>
                                                                        </button>
                                                                    </li>
                                                                ))
                                                            )}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div
                                    className="accordion-list border border-solid border-unicon-modalBorder rounded mb-5"
                                    style={{ boxShadow: '0px 1px 2px 0px #1018280D' }}
                                >
                                    <div
                                        className="py-[10px] px-[12px] flex justify-between items-center cursor-pointer border-b border-solid border-unicon-modalBorder bg-unicon-white rounded-t"
                                        onClick={() => {
                                            toggleAccordion(1);
                                        }}
                                    >
                                        <h4 className="font-medium text-base text-unicon-lightBlue inline-flex items-center justify-start">
                                            <span className="w-5 h-5 rounded-full flex items-center justify-center text-unicon-white bg-unicon-accordionNum text-[11px] font-extrabold mr-2">
                                                2
                                            </span>
                                            Model Allocation
                                        </h4>
                                        <span className="icon icon-collapsed">{openIndex === 1}</span>
                                    </div>
                                    {openIndex === 1 && (
                                        <div className="py-[10px] px-[16px]  bg-unicon-white rounded-b">
                                            <div className="flex  justify-between gap-4">
                                                {/* right */}
                                                <div className="w-1/2  pr-[30px]">
                                                    <p className="text-sm text-unicon-gray font-normal pt-[10px]">
                                                        Select users/ AD groups to add in the new pool.
                                                    </p>
                                                    <p className="text-sm text-unicon-gray font-normal pt-[10px]">
                                                        Lorem ipsum dolor sit amet consectetur. Id mauris ut rhoncus dui
                                                        cursus lectus donec tellus dui. Mauris gravida bibendum nisl
                                                        aenean vel.
                                                    </p>
                                                    <div className="border-t border-solid border-unicon-modalBorder mt-[20px]">
                                                        <h4 className="w-full font-medium text-base text-unicon-lightBlue py-[12px]">
                                                            Selection
                                                        </h4>
                                                        <ul className="scroll-div inline-flex justify-start overflow-x-hidden overflow-y-auto gap-3 max-h-[150px] w-full flex-wrap ">
                                                            {selectedModels.map((model) => {
                                                                return (
                                                                    <li
                                                                        className="flex items-center justify-between h-7 rounded-2xl gap-[0.5rem] truncate py-4 pl-3 pr-2 bg-unicon-chatLiHover"
                                                                        key={model.id}
                                                                    >
                                                                        <span className="font-medium text-base text-unicon-blue min-w-[58px] max-w-[130px]">
                                                                            {model.name}
                                                                        </span>
                                                                        <i
                                                                            className="icon icon-close-pills cursor-pointer"
                                                                            onClick={() => {
                                                                                toggleModels(model);
                                                                            }}
                                                                        ></i>
                                                                    </li>
                                                                );
                                                            })}
                                                        </ul>
                                                    </div>
                                                </div>

                                                {/* right */}
                                                <div className="w-1/2 pr-[5px]">
                                                    <div className="typeHeadWrapPool relative border border-solid border-unicon-modalBorder rounded-lg h-11 w-full bg-white py-[10px] px-4">
                                                        <input
                                                            type="text"
                                                            className="font-medium text-base text-unicon-lightBlue w-full pl-[30px] focus-visible:outline-none"
                                                        />
                                                    </div>
                                                    <div
                                                        className="filter-typeHeadWrap rounded-lg pt-[5px] pb-[5px] mt-[2px] border border-solid border-unicon-modalBorder"
                                                        style={{ boxShadow: '0px 1px 2px 0px #1018280D' }}
                                                    >
                                                        <span className="font-normal text-sm text-unicon-gray pl-[10px]">
                                                            Filters
                                                        </span>

                                                        <ul className="scroll-div w-full SelecteFilterList-wrap pt-[2px] pb-[5px] max-h-[152px] overflow-hidden overflow-y-auto">
                                                            {modelsList.map((model, index) => (
                                                                <li
                                                                    key={index}
                                                                    className="flex items-center justify-between h-15 px-5 py-[10px] w-full"
                                                                    onClick={() => {
                                                                        toggleModels(model);
                                                                    }}
                                                                >
                                                                    <div className="flex items-center justify-start">
                                                                        <span
                                                                            className="w-9 h-9 flex items-center justify-start"
                                                                            title={model.name}
                                                                        >
                                                                            <img
                                                                                src={modelPlaceholder}
                                                                                alt="modelPlaceholder"
                                                                                className="w-[36px] h-[36px]"
                                                                            />
                                                                        </span>
                                                                        <div
                                                                            className="font-medium text-unicon-lightBlue text-base leading-[18px] pl-[10px]"
                                                                            title={model.name}
                                                                        >
                                                                            {model.name}
                                                                            <br />
                                                                            <span
                                                                                className="text-unicon-gray text-sm leading-none"
                                                                                title={subTexts[index]}
                                                                            >
                                                                                {subTexts[index]}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <button className="w-6 h-6 flex items-center justify-center">
                                                                        <i
                                                                            className={`icon ${selectedModels.some((existingModels) => existingModels.id === model.id) ? 'icon-tick' : 'icon-plus-filter'}`}
                                                                        ></i>
                                                                    </button>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div
                                    className="accordion-list border border-solid border-unicon-modalBorder rounded  mb-5"
                                    style={{ boxShadow: '0px 1px 2px 0px #1018280D' }}
                                >
                                    <div
                                        className="py-[10px] px-[12px] flex justify-between items-center cursor-pointer border-b border-solid border-unicon-modalBorder bg-unicon-white rounded-t"
                                        onClick={() => {
                                            toggleAccordion(2);
                                        }}
                                    >
                                        <h4 className="font-medium text-base text-unicon-lightBlue inline-flex items-center justify-start">
                                            <span className="w-5 h-5 rounded-full flex items-center justify-center text-unicon-white bg-unicon-accordionNum text-[11px] font-extrabold mr-2">
                                                3
                                            </span>
                                            Token Entitlement
                                        </h4>
                                        <span className="icon icon-collapsed">{openIndex === 2}</span>
                                    </div>
                                    {openIndex === 2 && (
                                        <div className="py-[10px] px-[16px]  bg-unicon-white rounded-b">
                                            <div className="tab-headers flex items-center justify-start gap-6 h-6 mb-1 pl-[2px]">
                                                {selectedModels.map((model, index) => (
                                                    <button
                                                        key={index}
                                                        className={`tab-button ${index === activeTab ? 'active' : ''}`}
                                                        onClick={() => {
                                                            setActiveTab(index);
                                                        }}
                                                    >
                                                        {model.name}
                                                    </button>
                                                ))}
                                            </div>
                                            {modelsList.map((model, index) => {
                                                if (activeTab == index) {
                                                    return (
                                                        <Models1TableLayout
                                                            key={model.id}
                                                            poolName={poolName}
                                                            noOfUsers={selectedUsers.length}
                                                            tokenEntMonthly={
                                                                tokenEntitlements[model.id]?.tokenEntMonthly
                                                            }
                                                            tokenEntYearly={tokenEntitlements[model.id]?.tokenEntYearly}
                                                            updateTokenEntitlement={updateTokenEntitlement}
                                                            model={model}
                                                        />
                                                    );
                                                }
                                            })}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer border-t border-solid border-unicon-modalBorder flex items-center justify-end gap-4 w-full h-20 rounded-b-xl absolute bottom-0 px-[30px] bg-unicon-white">
                        <button
                            className="btn secondary-btn"
                            onClick={() => {
                                setIsModalOpen(false);
                            }}
                        >
                            Cancel
                        </button>
                        <button
                            className="btn primary-btn"
                            onClick={() => {
                                createPool();
                            }}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}
const PoolWithLayout = withLayout(Pool);

export default PoolWithLayout;
