import React from 'react';

const LoadingSpinner = ({ width = '64px', height = '64px' }: any) => {
    return (
        <div className="flex items-center justify-center mt-6">
            <div
                className="border-4 border-t-4 border-gray-200 border-solid rounded-full animate-spin border-t-blue-500"
                style={{ width: width, height: height }}
            ></div>
        </div>
    );
};

export default LoadingSpinner;
