import { Pool } from "../pools/PoolType";
import { BaseService } from "./BaseService";

export class PoolService extends BaseService {
    public getPoolsAsync = async (accessToken:string): Promise<Pool[]> => {
       
        const result = await this.getResponseAsync<Pool[]>(
            {
                commandPath: 'pools',
                method: 'GET',
            },
            accessToken,
        );

        return result;
    };

    public createPoolAsync = async (poolName:string,description:string,poolModelEntitlements:any,users:any,adGroups:any,createdBy:any,status:boolean,accessToken: string): Promise<any> => {
        const body:any = {
            "poolName": poolName,
            "description":description,
            "PoolModelEntitlements": poolModelEntitlements,
            "users": users,
            "adGroups": adGroups,
            "createdBy": createdBy,
            "status": status
        }
        const result = await this.getResponseAsync<any>(
            {
                commandPath: 'pools/create',
                method: 'POST',
                body,
            },
            accessToken,
        );

        return result;
    };

}
