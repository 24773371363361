import debug from 'debug';
import React, { ChangeEvent, useState } from 'react';
import { Constants } from '../../Constants';
import { COPY } from '../../assets/strings';
import { GetResponseOptions } from '../../libs/hooks/useChat';
import { AlertType } from '../../libs/models/AlertType';
import { ChatMessageType } from '../../libs/models/ChatMessage';
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { addAlert } from '../../redux/features/app/appSlice';
import { editConversationInput, updateBotResponseStatus } from '../../redux/features/conversations/conversationsSlice';
import { Alerts } from '../shared/Alerts';
import { updateUserIsTyping } from './../../redux/features/conversations/conversationsSlice';
import { ChatStatus } from './ChatStatus';

const log = debug(Constants.debug.root).extend('chat-input');

interface ChatInputProps {
    onSubmit: (options: GetResponseOptions) => Promise<void>;
}

export const ChatInput: React.FC<ChatInputProps> = ({ onSubmit }) => {
    const dispatch = useAppDispatch();
    const { conversations, selectedId } = useAppSelector((state: RootState) => state.conversations);
    const { activeUserInfo } = useAppSelector((state: RootState) => state.app);

    const [value, setValue] = useState('');

    const textAreaRef = React.useRef<HTMLTextAreaElement>(null);

    React.useEffect(() => {
        textAreaRef.current?.focus();
    }, [selectedId]);

    React.useEffect(() => {
        const chatState = conversations[selectedId];
        // setValue(chatState.disabled ? COPY.CHAT_DELETED_MESSAGE() : chatState.input);
    }, [conversations, selectedId]);

    const handleSubmit = (value: string, messageType: ChatMessageType = ChatMessageType.Message) => {
        if (value.trim() === '') {
            return;
        }
        setValue('');
        dispatch(editConversationInput({ id: selectedId, newInput: '' }));
        dispatch(updateBotResponseStatus({ chatId: selectedId, status: 'Calling the kernel' }));
        onSubmit({ value, messageType, chatId: selectedId }).catch((error) => {
            const message = `Error submitting chat input: ${(error as Error).message}`;
            log(message);
            dispatch(
                addAlert({
                    type: AlertType.Error,
                    message,
                }),
            );
        });
    };

    return (
        <div className="m-6">
            <div>
                <ChatStatus />
            </div>
            <Alerts />
            <div>
                <div className="border border-unicon-inputBorder rounded-[45px] p-4  w-[940px] min-h-[52px] mx-auto flex items-center justify-start">
                    <button className="h-7 w-7 flex items-center justify-center">
                        <i className="icon icon-mic"></i>
                    </button>
                    <button className="h-7 w-7 ml-[0.6rem] flex items-center justify-center">
                        <i className="icon icon-iconSet"></i>
                    </button>
                    <input
                        title="Chat input"
                        aria-label="Chat input field. Click enter to submit input."
                        id="chat-input"
                        disabled={conversations[selectedId].disabled}
                        className="w-full mx-[0.645rem] focus-visible:outline-none"
                        placeholder="Type message"
                        value={value}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            setValue(event.target.value);
                            dispatch(editConversationInput({ id: selectedId, newInput: event.target.value }));
                        }}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter' && !event.shiftKey) {
                                event.preventDefault();
                                handleSubmit(value);
                            }
                        }}
                        onBlur={() => {
                            if (activeUserInfo) {
                                dispatch(
                                    updateUserIsTyping({
                                        userId: activeUserInfo.id,
                                        chatId: selectedId,
                                        isTyping: false,
                                    }),
                                );
                            }
                        }}
                    />
                    <button className="h-7 w-7 flex items-center justify-center">
                        <i className="icon icon-enter"></i>
                    </button>
                </div>
            </div>
        </div>
    );
};
