import React, { useEffect, useState } from 'react';
import withLayout from '../dashboard/WithLayout';
import { Table, Header, HeaderRow, Body, Row, HeaderCell, Cell } from '@table-library/react-table-library/table';
import { useTheme } from '@table-library/react-table-library/theme';
import ProgressBar from '../utils/ProgressBar';
import Microsoft from '../../assets/microsoft.svg';
import Avatar from '../../assets/Avatar.svg';
import UserManageModal from '../utils/userManageModal';
import { UsersService } from '../../libs/services/UsersService';
import { useMsal } from '@azure/msal-react';
import { AuthHelper } from '../../libs/auth/AuthHelper';
import LoadingSpinner from '../../hooks/Loader';
interface Node {
    id: string;
    name: string;
    user_id: string;
    Role: string;
    assigned_on: string;
    pool_name: string;
    model_name: string;
    token_usage: number;
    Entitled_Tokens: number;
}

function UserManagement() {
    const { instance, inProgress } = useMsal();
    const userService = new UsersService();
    const [ids, setIds] = useState<string[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [usersList, setusersList] = useState<any>([]);

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };
    const handleExpand = (item: Node) => {
        if (ids.includes(item.id)) {
            setIds(ids.filter((id) => id !== item.id));
        } else {
            setIds(ids.concat(item.id));
        }
    };
    const [filterItems, setFilterItems] = useState([
        { id: 1, label: 'model 3' },
        { id: 2, label: 'model 4' },
    ]);
    const handleRemoveItem = (id: number) => {
        setFilterItems(filterItems.filter((item) => item.id !== id));
    };

    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('pool1');
    const [checkedPools, setCheckedPools] = useState<string[]>([]);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionSelect = (option: string): void => {
        setSelectedOption(option);
        setIsOpen(false);
    };

    const handleCheckboxChange = (pool: string) => {
        setCheckedPools(
            (prev) =>
                prev.includes(pool)
                    ? prev.filter((p) => p !== pool) // Uncheck pool
                    : [...prev, pool], // Check pool
        );
    };

    const theme = useTheme({
        HeaderRow: `
        .th {
         border-bottom:1px solid #EAECF0;
         padding:12px 24px;
         background-color: #F6F7FA;
         height:44px;
        }
      `,
        BaseCell: `
       &:first-of-type {
       >*{display:flex;}
       }
       
        &.icon-cell >div{
        display:inline-flex;
        align-item:center;
        justify-content:end;
       }
       border-bottom:1px solid #EAECF0;
       padding:12px 24px;
       height:60px;
      `,
    });

    const getUsersList = async () => {
        userService.getUsersAsync(await AuthHelper.getSKaaSAccessToken(instance, inProgress)).then((response) => {
            setusersList(response);
        });
    };

    useEffect(() => {
        getUsersList();
    }, []);

    const [usersDataForTable, setusersDataForTable] = useState<any>([]);

    useEffect(() => {
        const tempData: any = { nodes: [] };
        usersList.forEach((user) => {
            const date = new Date(user.createdOn);
            const formattedDate = date.toLocaleString('en-US', {
                month: 'short',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: true,
            });
            tempData.nodes.push({
                id: user.id,
                name: user.userName,
                user_id: user.emailid,
                Role: user.role,
                assigned_on: user.formattedDate,
                pool_name: user && user.pools && user.pools[0] ? user.pools[0].poolName : null,
                model_name: null,
                token_usage: null,
                Entitled_Tokens: null,
            });
        });
        setusersDataForTable(tempData);
    }, [usersList]);


    return (
        <div>
            <div className="flex justify-between items-center h-[60px] ">
                <div className="inputwrap h-10 flex items-center justify-start relative">
                    <i className="icon icon-input-search absolute left-0 cursor-pointer"></i>
                    <input
                        className="min-w-[332px] h-[32px] border-b border-[#D0D5DD] border-solid focus-visible:outline-none pl-[26px] text-sm text-unicon-lightBlue font-medium"
                        type="text"
                        placeholder="Search users/roles.."
                    />
                    <button
                        className={`w-10 h-10 flex items-center justify-center relative  ${filterItems.length >= 1 ? 'filter-active-icon' : ''} `}
                        style={{ transition: 'all 0.3s ease' }}
                    >
                        <i className="icon icon-filter"></i>
                    </button>
                </div>
                <div className="h-10 flex items-center justify-end">
                    <button
                        className="bg-[#387ADF] w-[117px] h-[36px] text-white rounded-lg text-base font-semibold flex items-center justify-center gap-[5px] "
                        onClick={toggleModal}
                    >
                        Add New <i className="icon icon-addnew"></i>
                    </button>
                    <button className="w-11 h-6 flex items-center justify-end ">
                        <i className="icon icon-excel"></i>
                    </button>
                </div>
            </div>
            <div
                className="border border-solid border-unicon-grayBorder rounded h-[calc(100vh-195px)]"
                style={{ boxShadow: '0px 1px 2px 0px #1018280F, 0px 1px 3px 0px #1018281A' }}
            >
                <div
                    className={`filter-list-wrpa flex justify-start py-3 px-6 min-h-[56px] ${filterItems.length === 0 ? 'hidden' : ''}`}
                    style={{ transition: 'all 0.3s ease' }}
                >
                    <span className="font-semibold text-base text-unicon-gray w-[65px] m-auto">Filters :</span>
                    <ul className="scroll-div inline-flex justify-start overflow-x-hidden overflow-y-auto gap-3 max-h-[100px] flex-wrap w-[calc(100%-65px)]">
                        {filterItems.map((item) => (
                            <li
                                key={item.id}
                                className="flex items-center justify-between h-7 rounded-2xl gap-[0.5rem] py-4 pl-3 pr-2 bg-unicon-chatLiHover"
                            >
                                <span className="font-medium text-base text-unicon-blue min-w-[58px] max-w-[130px] truncate">
                                    {item.label}
                                </span>
                                <i
                                    className="icon icon-close-pills cursor-pointer"
                                    onClick={() => {
                                        handleRemoveItem(item.id);
                                    }}
                                ></i>
                            </li>
                        ))}
                    </ul>
                </div>
                {usersDataForTable.nodes?.length > 0 ? (
                    <Table data={usersDataForTable} theme={theme} className="w-full rounded max-h-[calc(100vh-268px)] scroll-div scroll-smooth scroll-p-0">
                        {(tableList: Node[]) => (
                            <>
                                <Header>
                                    <HeaderRow>
                                        <HeaderCell className="text-unicon-lightBlue font-medium text-Hsm" title="Name">
                                            Name
                                        </HeaderCell>
                                        <HeaderCell className="text-unicon-lightBlue font-medium text-Hsm" title="Role">
                                            Role
                                        </HeaderCell>
                                        <HeaderCell
                                            className="text-unicon-lightBlue font-medium text-Hsm"
                                            title="Added On"
                                        >
                                            Added On
                                        </HeaderCell>
                                        <HeaderCell
                                            className="text-unicon-lightBlue font-medium text-Hsm"
                                            title="Pool Name"
                                        >
                                            Pool Name
                                        </HeaderCell>
                                        <HeaderCell
                                            className="text-unicon-lightBlue font-medium text-Hsm"
                                            title="Model Name"
                                        >
                                            Model
                                        </HeaderCell>
                                        <HeaderCell
                                            className="text-unicon-lightBlue font-medium text-Hsm"
                                            title="Token Usage (Total)"
                                        >
                                            Token Usage (Total)
                                        </HeaderCell>
                                    </HeaderRow>
                                </Header>

                                <Body>
                                    {tableList.map((item) => (
                                        <React.Fragment key={item.id}>
                                            <Row item={item}>
                                                <Cell
                                                    className="text-unicon-lightBlue font-medium text-base"
                                                    title={item.name?.toString()}
                                                >
                                                    <div
                                                        className="flex items-center justify-start"
                                                        onClick={() => {
                                                            handleExpand(item);
                                                        }}
                                                    >
                                                        {ids.includes(item.id) ? (
                                                            <button className="flex items-center justify-center w-[30px] h-[30px]">
                                                                <i className="icon icon-arrow-right up"></i>
                                                            </button>
                                                        ) : (
                                                            <button className="flex items-center justify-center w-[30px] h-[30px]">
                                                                <i className="icon icon-arrow-right"></i>
                                                            </button>
                                                        )}
                                                        <div className="flex items-center justify-start">
                                                            <span
                                                                className="w-9 h-9 flex items-center justify-start"
                                                                title={item.name?.toString()}
                                                            >
                                                                <img
                                                                    src={Avatar}
                                                                    alt="Avatar"
                                                                    className="w-[36px] h-[36px]"
                                                                />
                                                            </span>
                                                            <div
                                                                className="font-medium text-unicon-lightBlue text-base leading-[18px] pl-[10px] max-w-[148px] overflow-hidden text-ellipsis whitespace-nowrap block
                                                                "
                                                                title={item.name?.toString()}
                                                            >
                                                                {item.name}
                                                                <br />
                                                                <span
                                                                    className="text-unicon-gray text-sm leading-none"
                                                                    title={item.user_id?.toString()}
                                                                >
                                                                    User ID:{item.user_id}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Cell>
                                                 <Cell
                                                    className="text-unicon-lightBlue font-medium text-base capitalize"
                                                    title={item.Role?.toString()}
                                                >
                                                    {item.Role}
                                                </Cell>
                                                <Cell
                                                    className="text-unicon-lightBlue font-medium text-base"
                                                    title={item.assigned_on?.toString()}
                                                >
                                                    {item.assigned_on?.toString()}
                                                </Cell>
                                                <Cell className="text-unicon-lightBlue font-medium text-base"></Cell>
                                                <Cell className="text-unicon-lightBlue font-medium text-base"></Cell>
                                                <Cell className="text-unicon-lightBlue font-medium text-base"></Cell>
                                            </Row>

                                            {ids.includes(item.id) && (
                                                <Row item={item}>
                                                    <Cell className="text-unicon-lightBlue font-medium text-base"></Cell>
                                                    <Cell className="text-unicon-lightBlue font-medium text-base">
                                                        {' '}
                                                    </Cell>
                                                    <Cell className="text-unicon-lightBlue font-medium text-base"></Cell>
                                                    <Cell
                                                        className="text-unicon-lightBlue font-medium text-base"
                                                        title={item.pool_name?.toString()}
                                                    >
                                                        {item.pool_name}
                                                    </Cell>
                                                    <Cell
                                                        className="text-unicon-lightBlue font-medium text-base"
                                                        title={item.model_name?.toString()}
                                                    >
                                                        {' '}
                                                        <div className="flex items-center justify-start cursor-pointer">
                                                            <img
                                                                src={Microsoft}
                                                                alt="microsoft"
                                                                className="w-[22px] h-[22px] pr-[3px]"
                                                            />
                                                            {item.model_name}
                                                        </div>
                                                    </Cell>
                                                    <Cell className="text-unicon-lightBlue font-medium text-base">
                                                        <ProgressBar value={50} max={100} />
                                                    </Cell>
                                                </Row>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </Body>
                            </>
                        )}
                    </Table>
                ) : (
                    <LoadingSpinner />
                )}
            </div>
            <UserManageModal isOpen={isModalOpen} onClose={toggleModal} showIcon={false} title="Add User" subtitle="">
                <div className="modalbody w-full relative min-h-[calc(100vh-24rem)] max-h-[calc(100vh-19.3rem)] rounded-xl">
                    <div className="modal-content-wrap min-h-[calc(100vh-26rem)] max-h-[calc(100vh-21.3rem)] ">
                        <div className="form-group-row  flex  justify-between gap-7 py-[25px] px-[30px] bg-unicon-white">
                            <div className="form-group-col w-full relative">
                                <label className="block font-semibold text-base text-unicon-lightBlue mb-[6px]">
                                    User Name
                                </label>
                                <div
                                    className="user-modal-pool-name w-full border border-unicon-inputBorder  h-11 flex items-center justify-start rounded-lg  relative"
                                    style={{ boxShadow: '0px 1px 2px 0px #1018280D' }}
                                >
                                    <input
                                        className="w-[calc(100%-32px)] h-8 font-normal text-base text-unicon-lightBlue  flex items-center  pl-[14px] pr-[10px] bg-unicon-white  focus-visible:outline-none"
                                        type="text"
                                        placeholder="Enter User Name"
                                        value="Pool 4"
                                    />
                                </div>
                                <div
                                    className="filter-typeHeadWrap rounded-lg  mt-[2px] border border-solid border-unicon-modalBorder absolute top-[72px] z-[2] w-full bg-unicon-white "
                                    style={{ boxShadow: '0px 12px 16px -4px #10182814,0px 4px 6px -2px #10182808' }}
                                >
                                    <ul className=" scroll-div w-full  pt-[2px] pb-[5px] min-h-[100px] max-h-[300px] overflow-hidden overflow-y-auto">
                                        {[
                                            { name: 'Jacob Jones', title: 'Product Manager' },
                                            { name: 'Jason Will', title: 'Software Tester' },
                                            { name: 'Jason Will', title: 'CEO' },
                                        ].map((item, index) => (
                                            <li
                                                key={index}
                                                className="flex items-center justify-between h-15 px-[18px] py-[10px] w-full hover:bg-unicon-dashboardlistHover cursor-pointer"
                                            >
                                                <div className="flex items-center justify-start">
                                                    <span
                                                        className="w-9 h-9 flex items-center justify-start"
                                                        title={item.name}
                                                    >
                                                        <img src={Avatar} alt="Avatar" className="w-[36px] h-[36px]" />
                                                    </span>
                                                    <div
                                                        className="font-medium text-unicon-lightBlue text-base leading-[18px] pl-[10px]"
                                                        title={item.name}
                                                    >
                                                        {item.name}
                                                        <br />
                                                        <span
                                                            className="text-unicon-gray text-sm leading-none"
                                                            title={`${item.name.toLowerCase().replace(' ', '')}@mail.com`}
                                                        >
                                                            {`${item.name.toLowerCase().replace(' ', '')}@mail.com`}
                                                        </span>
                                                    </div>
                                                </div>
                                                <span
                                                    className="max-w-[140px] rounded-2xl bg-unicon-chatLiHover flow-root py-[2px] px-2 text-sm font-medium text-unicon-blue overflow-hidden text-ellipsis  whitespace-nowrap line-clamp-1"
                                                    title={item.title}
                                                >
                                                    {item.title}
                                                </span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>

                            <div className="form-group-col w-full relative">
                                <label className="block font-semibold text-base text-unicon-lightBlue mb-[6px]">
                                    Pool Selection<span className="text-[#C32B0A] text-base font-semibold">*</span>
                                </label>
                                <button
                                    className="px-[14px]  w-full flex items-center justify-between bg-unicon-white dropdown-button pool-dropdown-button rounded-lg h-11"
                                    onClick={toggleDropdown}
                                >
                                    <span className="font-medium text-base text-unicon-lightBlue leading-4 w-[36px] capitalize">
                                        {selectedOption}
                                    </span>
                                    <i className="icon icon-collapsed"></i>
                                </button>
                                {isOpen && (
                                    <div className="dropdown-content pool-Dropdown py-2">
                                        {['pool1', 'pool2', 'pool3', 'pool4', 'pool5', 'pool6'].map((pool) => (
                                            <label key={pool} className="flex items-center space-x-2">
                                                <a
                                                    href="#"
                                                    onClick={() => {
                                                        handleOptionSelect(pool);
                                                    }}
                                                >
                                                    <input
                                                        className="w-[22px] h-[22px] border-[1.3px] border-solid border-unicon-CheckBox rounded-sm cursor-pointer"
                                                        type="checkbox"
                                                        checked={checkedPools.includes(pool)}
                                                        onChange={() => {
                                                            handleCheckboxChange(pool);
                                                        }}
                                                    />
                                                    <span className="text-base text-unicon-lightBlue font-medium pl-3 flex items-center justify-start capitalize">
                                                        {pool}
                                                    </span>
                                                </a>
                                            </label>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className=" px-[30px]">
                            <h4 className="text-xl  font-medium text-unicon-lightBlue pb-[15px]">User Pool Mapping</h4>
                            <div
                                className="UserPoolMapTable border border-solid border-unicon-grayBorder rounded "
                                style={{ boxShadow: '0px 1px 2px 0px #1018280F, 0px 1px 3px 0px #1018281A' }}
                            >
                                <Table
                                    data={usersDataForTable}
                                    theme={theme}
                                    className="w-full rounded max-h-[260px] scroll-div scroll-smooth scroll-p-0"
                                >
                                    {(tableList: Node[]) => (
                                        <>
                                            <Header>
                                                <HeaderRow>
                                                    <HeaderCell
                                                        className="text-unicon-lightBlue font-medium text-Hsm"
                                                        title="Pool"
                                                    >
                                                        Pool{' '}
                                                    </HeaderCell>
                                                    <HeaderCell
                                                        className="text-unicon-lightBlue font-medium text-Hsm"
                                                        title="Model Name"
                                                    >
                                                        Model Name
                                                    </HeaderCell>
                                                    <HeaderCell
                                                        className="text-unicon-lightBlue font-medium text-Hsm"
                                                        title="Entitled Tokens"
                                                    >
                                                        Entitled Tokens
                                                    </HeaderCell>
                                                    <HeaderCell
                                                        className="text-unicon-lightBlue font-medium text-Hsm"
                                                        title="Token Usage (Total)"
                                                    >
                                                        Token Usage (Total)
                                                    </HeaderCell>
                                                </HeaderRow>
                                            </Header>

                                            <Body>
                                                {tableList.map((item) => (
                                                    <React.Fragment key={item.id}>
                                                        <Row item={item}>
                                                            <Cell
                                                                className="text-unicon-lightBlue font-medium text-base"
                                                                title={item.pool_name?.toString()}
                                                            >
                                                                {item.pool_name}
                                                            </Cell>
                                                            <Cell
                                                                className="text-unicon-lightBlue font-medium text-base"
                                                                title={item.model_name?.toString()}
                                                            >
                                                                {' '}
                                                                <div className="flex items-center justify-start cursor-pointer">
                                                                    <img
                                                                        src={Microsoft}
                                                                        alt="microsoft"
                                                                        className="w-[22px] h-[22px] pr-[3px]"
                                                                    />
                                                                    {item.model_name}
                                                                </div>
                                                            </Cell>
                                                            <Cell
                                                                className="text-unicon-lightBlue font-medium text-base"
                                                                title={item.Entitled_Tokens?.toString()}
                                                            >
                                                                {item.Entitled_Tokens}
                                                            </Cell>
                                                            <Cell className="text-unicon-lightBlue font-medium text-base">
                                                                <ProgressBar value={50} max={100} />
                                                            </Cell>
                                                        </Row>
                                                    </React.Fragment>
                                                ))}
                                            </Body>
                                        </>
                                    )}
                                </Table>
                            </div>
                        </div>
                    </div>

                    <div className="modal-footer  border-t border-solid border-unicon-modalBorder flex items-center justify-end gap-4 w-full h-20 rounded-b-xl absolute bottom-0 px-[30px] ">
                        <button
                            className="btn secondary-btn"
                            onClick={() => {
                                setIsModalOpen(false);
                            }}
                        >
                            Cancel
                        </button>
                        <button
                            className="btn primary-btn"
                            onClick={() => {
                                setIsModalOpen(false);
                            }}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </UserManageModal>
        </div>
    );
}
const UserManageWithLayout = withLayout(UserManagement);

export default UserManageWithLayout;
