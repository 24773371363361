import React from 'react';

interface ProgressBarProps {
    value: number;
    max: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ value, max }) => {
    const percentage = (value / max) * 100;

    return (
        <div className="flex flex-col items-center">
            <span className="text-unicon-gray font-medium text-base text-end pr-[2px] w-full">
                {value} / {max}
            </span>
            <div style={{ width: '100%', marginTop: '4px' }}>
                <div
                    style={{
                        width: '100%',
                        position: 'relative',
                        height: '8px',
                        borderRadius: '4px',
                        backgroundColor: '#E7EAF1',
                    }}
                >
                    <div
                        style={{
                            width: `${percentage}%`,
                            height: '100%',
                            borderRadius: '4px',
                            backgroundColor: '#FBA834',
                            transition: 'width 0.3s ease-in-out',
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default ProgressBar;
