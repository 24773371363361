import microsoftIcon from '../../assets/microsoft.svg';
import downArrow from '../../assets/down_arrow.svg';
import notification from '../../assets/notification.svg';
import theme from '../../assets/theme.svg';
import { useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { getInitials } from '../utils/Helper';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import SettingModal from '../utils/settingModal';
import ProgressBarModal from '../utils/progressBarModal';
import profileimg  from'../../assets/setting-modal/profileimg.svg';
import chatimage1 from'../../assets/setting-modal/image-1.svg';
import chatimage2 from'../../assets/setting-modal/microsoft.svg';
import Archivedtable from '../ArchiveTable';
import loginuserImage from '../../assets/setting-modal/profileimg.svg';
const AdminTopHeader = () => {
    const location = useLocation(); // Get the current location
    const { activeUserInfo } = useAppSelector((state: RootState) => state.app);
    const navigate = useNavigate();
    const [showMenu, setshowMenu] = useState(false);
    const [selectedItem, setSelectedItem] = useState('MyProfile');
    const [isModalOpen, setIsModalOpen] = useState(false);
    // const headerMenu = [ 'Dashboard'];
    const headerSettingMenu = [ 'settings', 'Privacy & Security'];
    

    const transformString = (str: string) => {
        return str.replace(/\//g, '').replace(/-/g, ' ');
    };
    const handleItemClick = (itemName: string)=> {
        setSelectedItem(itemName);
      };

    const getDynamicHeading = () => {
        if (location.pathname === '/') {
            return (
                <div className="h-[44px]  hii px-3 rounded-[8px] flex  items-center justify-between gap-4 border border-[#E9EDF7]" style={{boxShadow: '0px 1px 2px 0px #1018280D'}}>
                    <span className='inline-flex items-center justify-start'>
                    <img src={microsoftIcon} alt="microsoft icon" width={22} height={22} />
                    <h3 className="text-xl leading-5 font-medium text-unicon-lightBlue pl-2" >ChatBolt</h3>
                    </span>
                    <span className='w-[30px] h-[30px] flex items-center justify-end'>
                    <img src={downArrow} alt="microsoft icon" width={28} height={28} />
                    </span>
                </div>
            );
        }

        return (
            <div className="h-[44px] py-2 rounded-[8px] flex gap-4 ">
                <h3 className="text-xxl font-semibold text-unicon-lightBlue capitalize">
                    {transformString(location.pathname)}
                </h3>
            </div>
        );
    };


    return (
        <div className="pl-8 pr-9 h-[76px] flex items-center justify-between border-b border-solid border-unicon-grayBorder">
            {getDynamicHeading()}
            <div className="h-[42px] min-w-[190px]  rounded-[30px] flex items-center justify-end gap-2">
                <img src={notification} alt="microsoft icon" width={24} height={24} />
                <img src={theme} alt="microsoft icon" width={24} height={24} />
                <div className="relative">
                    <div
                        className="flex items-center justify-center w-[42px] h-[42px] rounded-full bg-unicon-gray text-white text-xl font-semibold cursor-pointer"
                        onClick={() => {
                            setshowMenu((showMenu) => !showMenu);
                        }}
                    >
                        {getInitials(activeUserInfo?.username)}
                    </div>
                    {showMenu && (
                        <div className="absolute right-0 rounded-[14px]  bg-white px-5 py-3 border w-[311px] z-50 hello" style={{boxShadow: '14px 17px 40px 4px #7090B02E'}}>
                            <div className='flex items-center justify-start w-full'>
                                <span className='w-[60px] h-[60px] flex items-center justify-center'>
                                <img src={loginuserImage} alt='user' width={51} height={51} className='rounded-full'/>
                                </span>
                                <h4 className='w-[calc(100%-65px)] font-semibold text-xl text-unicon-lightBlue pl-3'>Praveen Srikanth <br/>
                                <span className='font-medium text-base text-unicon-gray'>Admin</span></h4>
                            </div>
                            <div className='border-t border-solid border-unicon-modalBorder mt-3'>
                                <span className='block px-4 pt-4  text-sm text-unicon-gray font-medium'>General</span>
                            {headerSettingMenu.map((item: string, index: number) => {
                                return (
                                    <div
                                        key={index}
                                        className="text-base font-semibold text-unicon-lightBlue leading-4 capitalize cursor-pointer pl-4 h-[30px] w-full rounded inline-flex items-center justify-start hover:bg-unicon-chatLiHover"
                                        onClick={() => {
                                            if (item !== 'settings') {
                                                 navigate(`/${item}`);
                                            }
                                            setIsModalOpen(true);
                                        }}
                                    >
                                        {item}
                                    </div>
                                );
                            })}
                            </div>

                            <div className='border-t border-solid border-unicon-modalBorder mt-3'>
                                <span className='block px-4 pt-4  text-sm text-unicon-gray font-medium'>Administration</span>
                                <div className="text-base font-semibold text-unicon-lightBlue leading-4 capitalize cursor-pointer pl-4 h-[30px] w-full rounded inline-flex items-center justify-start hover:bg-unicon-chatLiHover">Admin Panel</div>
                            </div>

                            <div className='border-t border-solid border-unicon-modalBorder mt-3'>
                                <span className='block px-4 pt-4  text-sm text-unicon-gray font-medium'>Policy and account terms</span>
                                <div className="text-base font-semibold text-unicon-lightBlue leading-4 capitalize cursor-pointer pl-4 h-[30px] w-full rounded inline-flex items-center justify-start hover:bg-unicon-chatLiHover">Privacy Policy</div>
                                <div className="text-base font-semibold text-unicon-lightBlue leading-4 capitalize cursor-pointer pl-4 h-[30px] w-full rounded inline-flex items-center justify-start hover:bg-unicon-chatLiHover">Terms & Conditions</div>
                                <button className='btn primary-btn rounded-[10px] flex items-center justify-center h-10 w-full mt-6  mb-4'>
                                    <i className='icon icon-logout'></i><span className='text-base  font-semibold'>Sign out</span>
                                </button>
                            </div>

                            
                        </div>
                    )}
                </div>
            </div>
            
              <SettingModal isOpen={isModalOpen}  onClose={() => {
                setIsModalOpen(false);
            }} title="Settings">
            <div className='modalbody w-full relative h-[calc(100vh-14.3rem)]'>
               <div className='modal-content-wrap flex justify-start  h-[calc(100vh-19.3rem)]'>
                <div className='modal-left-pannel w-[226px]  border-r border-solid border-unicon-grayBorder h-[calc(100vh-19.4rem)]'>
                <ul className='mt-1'>
              <li
                className={`h-12 w-full px-[28px] flex items-center justify-start bg-unicon-white gap-2 cursor-pointer ${
                  selectedItem === 'MyProfile' ? 'active-list' : ''
                }`}
                onClick={() => {handleItemClick('MyProfile')}}
              >
                <i className='icon icon-profile'></i>
                <span className='text-unicon-lightBlue text-base font-medium capitalize'>
                  My Profile & Usage
                </span>
              </li>
              <li
                className={`h-12 w-full px-[28px] flex items-center justify-start bg-unicon-white gap-2 cursor-pointer ${
                  selectedItem === 'ArchiveChat' ? 'active-list' : ''
                }`}
                onClick={() => {handleItemClick('ArchiveChat')}}
              >
                <i className='icon icon-archive'></i>
                <span className='text-unicon-lightBlue text-base font-medium capitalize'>
                  Archived Chats
                </span>
              </li>
              <li
                className={`h-12 w-full px-[28px] flex items-center justify-start bg-unicon-white gap-2 cursor-pointer ${
                  selectedItem === 'MyActivity' ? 'active-list' : ''
                }`}
                onClick={() => {handleItemClick('MyActivity')}}
              >
                <i className='icon icon-activity'></i>
                <span className='text-unicon-lightBlue text-base font-medium capitalize'>
                  My Activity
                </span>
              </li>
            </ul>
                </div>
                <div className='modal-right-pannel w-[calc(100%-226px)] h-[calc(100vh-19.3rem)] overflow-auto  px-[30px]'>
                {selectedItem === 'MyProfile' && <div className='my-profile-main w-full pt-[25px] pb-[18px]'>
                        <div className='w-full border border-unicon-modalBorder border-solid rounded'>
                                <div className='flex items-center justify-start border-b border-unicon-modalBorder  p-[10px] relative rounded-t'>
                                    <span className='w-[76px] h[76px] flex items-center justify-center rounded-full'>
                                        <img src={profileimg} alt="profile icon" width={65} height={65}/>
                                    </span>
                                    <h3 className='text-xl font-semibold  text-unicon-lightBlue leading-6 pl-[12px]'>
                                    Praveen Srikanth<br/>
                                        <span className='text-base leading-4 text-unicon-gray font-medium'>Admin</span>
                                    </h3>
                                    <button className='edit-button absolute top-2 right-2 w-7 h-7'>
                                    <i className='icon icon-rename modal-edit-icon'></i>
                                    </button>
                                </div>
                            <form className='profile-form px-[30px] py-[25px]'>
                                <div className='form-group-row  flex gap-4 mb-[14px]'>
                                    <div className='form-group-col'>
                                    <label className='block font-medium text-base text-unicon-lightBlue mb-[6px]'>First Name</label>
                                    <input className='min-w-[270px]  font-normal text-base text-unicon-lightBlue border border-unicon-inputBorder h-9 rounded flex items-center justify-start px-[14px] bg-unicon-white focus-visible:outline-none' type='text' name='first-name' placeholder='Enter First Name'></input>
                                    </div>
                                    <div className='form-group-col'>
                                    <label className='block font-medium text-base text-unicon-lightBlue mb-[6px]'>Last Name</label>
                                    <input className='min-w-[270px]  font-normal text-base text-unicon-lightBlue border border-unicon-inputBorder h-9 rounded flex items-center justify-start px-[14px] bg-unicon-white focus-visible:outline-none' type='text' name='last-name' placeholder='Enter last Name'></input>
                                    </div>
                                </div>
                                <div className='form-group-row  flex gap-4 mb-[14px]'>
                                    <div className='form-group-col'>
                                    <label className='block font-medium text-base text-unicon-lightBlue mb-[6px]'>Email ID</label>
                                        <div className='inline-flex items-center justify-start border-unicon-inputBorder h-9 rounded border border-solid px-[14px]'>
                                            <i className='icon email-icon'></i> <input className=' min-w-[270px]  font-normal text-base text-unicon-lightBlue   flex items-center justify-start pl-[5px]  bg-unicon-white focus-visible:outline-none ' type='email' name='email' placeholder='Enter Email ID'></input>
                                        </div>
                                    </div>
                                        <div className='form-group-col'>
                                        <label className='block font-medium text-base text-unicon-lightBlue mb-[6px]'>Contact No.</label>
                                        <div className='inline-flex items-center justify-start border-unicon-inputBorder h-9 rounded border border-solid px-[14px]'>
                                        <i className='icon contact-icon'></i> <input className=' min-w-[270px]  font-normal text-base text-unicon-lightBlue   flex items-center justify-start pl-[5px]  bg-unicon-white focus-visible:outline-none '  type='number' name='contact' placeholder='1 646 300 2454'></input>
                                        </div>
                                    </div>
                                </div>

                                <div className='form-group-row  flex gap-4 '>
                                        <div className='form-group-col'>
                                        <label className='block font-medium text-base text-unicon-lightBlue mb-[6px]'>Location</label>
                                        <div className='inline-flex items-center justify-start border-unicon-inputBorder h-9 rounded border border-solid px-[14px]'>
                                        <i className='icon location-icon'></i> <input className=' min-w-[270px]  font-normal text-base text-unicon-lightBlue   flex items-center justify-start pl-[5px]  bg-unicon-white focus-visible:outline-none '  type='number' name='contact' placeholder='New York'></input>
                                        </div>
                                    </div>
                                </div>


                                
                            </form>

                        </div>
                        <div className='mt-5'>
                            <h3 className='text-lg text-unicon-lightBlue font-medium'>Model Usage</h3>
                            <div className='flex flex-1 gap-4 mt-[6px]'>
                                <div className='card-wrap'>
                                    <div className='card-header inline-flex items-center justify-start'>
                                        <span className='w-[42px] h-[42px] flex items-center justify-start'>
                                            <img src={chatimage1} alt="microsoft icon" width={40} height={40}/>
                                        </span>
                                        <h4 className='pl-3 flex  flex-col'>
                                            <span className='text-base text-unicon-lightBlue font-medium mb-[3px]'>gpt-4o</span>
                                            <span className='text-unicon-gray text-sm font-normal '>Chat completion</span> 
                                        </h4>
                                    </div>
                                    <div className='w-full pt-[10px]'>
                                    <ProgressBarModal value={56} max={100} />
                                    </div>
                                </div>
                                <div className='card-wrap'>
                                    <div className='card-header inline-flex items-center justify-start'>
                                        <span className='w-[42px] h-[42px] flex items-center justify-start'>
                                            <img src={chatimage2} alt="microsoft icon" width={40} height={40}/>
                                        </span>
                                        <h4 className='pl-3 flex  flex-col'>
                                            <span className='text-base text-unicon-lightBlue font-medium mb-[3px]'>gpt-4-32k</span>
                                            <span className='text-unicon-gray text-sm font-normal '>Chat completion</span> 
                                        </h4>
                                    </div>
                                    <div className='w-full pt-[10px]'>
                                    <ProgressBarModal value={78} max={100} />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>}
                {selectedItem === 'ArchiveChat' && <div className='archive-Chats pt-[16px] pb-[18px]'>
                    <h4 className='font-medium text-lg text-unicon-lightBlue pb-[8px]'>Archived Chats</h4>
                    <div className='w-full'>
                        <Archivedtable/>
                    </div>
                    </div>}

                {selectedItem === 'MyActivity' && <div className='my-activity pt-[16px] pb-[18px]'>
                    <h4 className='font-medium text-lg text-unicon-lightBlue pb-[8px]'>My Activity</h4>
                        <div className='w-full border border-unicon-grayBorder rounded  pt-[25px]'>
                            <div className='h-[calc(100vh-424px)] overflow-hidden overflow-y-auto scroll-div'>
                                    <div>
                                        <h4 className='flex items-center justify-start pl-[25px]'>
                                        <span className='rounded-full w-[6px] h-[6px] bg-unicon-dotColor'></span>
                                        <span className='text-unicon-lightBlue font-semibold text-base leading-4 pl-2'>Today</span>
                                        </h4>
                                            <ul className='activity-list relative my-[6px] pb-4'>
                                                <li className='h-[56px] flex justify-between p-[12px] pl-[42px]  hover:bg-unicon-activityhover'>
                                                    <div className='inline-flex justify-start'>
                                                        <span className='w-[22px] h-[22px] flex items-center justify-center rounded-full bg-unicon-modalBglogged'>
                                                            <i className='icon icon-logged'></i>
                                                        </span>
                                                        <h5 className='pl-3 text-base font-medium text-unicon-lightBlue'>Logged in<br/>
                                                        <span className='inline-flex items-center justify-start '>
                                                            <i className='icon icon-time'></i><span className='text-unicon-gray text-xs font-normal pl-[3px]'>09: 20 AM</span></span>
                                                        </h5>
                                                    </div>
                                                    
                                                </li>

                                                <li className='h-[56px] flex justify-between p-[12px] pl-[42px]  hover:bg-unicon-activityhover'>
                                                    <div className='inline-flex justify-start'>
                                                        <span className='w-[22px] h-[22px] flex items-center justify-center rounded-full bg-unicon-modalBgearch'>
                                                            <i className='icon icon-archived'></i>
                                                        </span>
                                                        <h5 className='pl-3 text-base font-medium text-unicon-lightBlue'>Archived  a chat<br/>
                                                        <span className='inline-flex items-center justify-start '>
                                                            <i className='icon icon-time'></i><span className='text-unicon-gray text-xs font-normal pl-[3px]'>11: 50 AM</span></span>
                                                        </h5>
                                                    </div>
                                                    <div className='text-sm font-normal text-unicon-gray'>Chat ID:<a className='text-unicon-blue pl-[3px] cursor-pointer'>11<span className='font-medium'>3562</span></a>
                                                    </div>
                                                </li>
                                            </ul>
                                    </div>

                                    <div>
                                        <h4 className='flex items-center justify-start pl-[25px]'>
                                        <span className='rounded-full w-[6px] h-[6px] bg-unicon-dotColor'></span>
                                        <span className='text-unicon-lightBlue font-semibold text-base leading-4 pl-2'>17 Aug, 2024</span>
                                        </h4>
                                            <ul className='activity-list relative my-[6px] pb-4'>
                                                <li className='h-[56px] flex justify-between p-[12px] pl-[42px]  hover:bg-unicon-activityhover'>
                                                    <div className='inline-flex justify-start'>
                                                        <span className='w-[22px] h-[22px] flex items-center justify-center rounded-full bg-unicon-modalBglogged'>
                                                            <i className='icon icon-logged'></i>
                                                        </span>
                                                        <h5 className='pl-3 text-base font-medium text-unicon-lightBlue'>Logged in<br/>
                                                        <span className='inline-flex items-center justify-start '>
                                                            <i className='icon icon-time'></i><span className='text-unicon-gray text-xs font-normal pl-[3px]'>09: 20 AM</span></span>
                                                        </h5>
                                                    </div>
                                                    
                                                </li>

                                                <li className='h-[56px] flex justify-between p-[12px] pl-[42px]  hover:bg-unicon-activityhover'>
                                                    <div className='inline-flex justify-start'>
                                                        <span className='w-[22px] h-[22px] flex items-center justify-center rounded-full bg-unicon-modalBgearch'>
                                                            <i className='icon icon-archived'></i>
                                                        </span>
                                                        <h5 className='pl-3 text-base font-medium text-unicon-lightBlue'>Archived  a chat<br/>
                                                        <span className='inline-flex items-center justify-start '>
                                                            <i className='icon icon-time'></i><span className='text-unicon-gray text-xs font-normal pl-[3px]'>11: 00 AM</span></span>
                                                        </h5>
                                                    </div>
                                                    <div className='text-sm font-normal text-unicon-gray'>Chat ID:<a className='text-unicon-blue pl-[3px] cursor-pointer'>11<span className='font-medium'>3329</span></a>
                                                    </div>
                                                </li>

                                                <li className='h-[56px] flex justify-between p-[12px] pl-[42px]  hover:bg-unicon-activityhover'>
                                                    <div className='inline-flex justify-start'>
                                                        <span className='w-[22px] h-[22px] flex items-center justify-center rounded-full bg-unicon-modalBgdel'>
                                                            <i className='icon icon-delete'></i>
                                                        </span>
                                                        <h5 className='pl-3 text-base font-medium text-unicon-lightBlue'>Deleted  a chat<br/>
                                                        <span className='inline-flex items-center justify-start '>
                                                            <i className='icon icon-time'></i><span className='text-unicon-gray text-xs font-normal pl-[3px]'>12: 50 PM</span></span>
                                                        </h5>
                                                    </div>
                                                    <div className='text-sm font-normal text-unicon-gray'>Chat ID:<a className='text-unicon-blue pl-[3px] cursor-pointer'>11<span className='font-medium'>3329</span></a>
                                                    </div>
                                                </li>
                                            </ul>
                                    </div>

                                    <div>
                                        <h4 className='flex items-center justify-start pl-[25px]'>
                                        <span className='rounded-full w-[6px] h-[6px] bg-unicon-dotColor'></span>
                                        <span className='text-unicon-lightBlue font-semibold text-base leading-4 pl-2'>16 Aug, 2024</span>
                                        </h4>
                                            <ul className='activity-list relative my-[6px] pb-4'>
                                                <li className='h-[56px] flex justify-between p-[12px] pl-[42px]  hover:bg-unicon-activityhover'>
                                                    <div className='inline-flex justify-start'>
                                                        <span className='w-[22px] h-[22px] flex items-center justify-center rounded-full bg-unicon-modalBgedit'>
                                                            <i className='icon icon-modal-edit'></i>
                                                        </span>
                                                        <h5 className='pl-3 text-base font-medium text-unicon-lightBlue'>Edited a chat<br/>
                                                        <span className='inline-flex items-center justify-start '>
                                                            <i className='icon icon-time'></i><span className='text-unicon-gray text-xs font-normal pl-[3px]'>12: 50 PM</span></span>
                                                        </h5>
                                                    </div>
                                                    <div className='text-sm font-normal text-unicon-gray'>Chat ID:<a className='text-unicon-blue pl-[3px] cursor-pointer'>11<span className='font-medium'>3429</span></a>
                                                    </div>
                                                </li>

                                                <li className='h-[56px] flex justify-between p-[12px] pl-[42px]  hover:bg-unicon-activityhover'>
                                                    <div className='inline-flex justify-start'>
                                                        <span className='w-[22px] h-[22px] flex items-center justify-center rounded-full bg-unicon-modalBgearch'>
                                                            <i className='icon icon-archived'></i>
                                                        </span>
                                                        <h5 className='pl-3 text-base font-medium text-unicon-lightBlue'>Archived  a chat<br/>
                                                        <span className='inline-flex items-center justify-start '>
                                                            <i className='icon icon-time'></i><span className='text-unicon-gray text-xs font-normal pl-[3px]'>11: 00 AM</span></span>
                                                        </h5>
                                                    </div>
                                                    <div className='text-sm font-normal text-unicon-gray'>Chat ID:<a className='text-unicon-blue pl-[3px] cursor-pointer'>11<span className='font-medium'>3329</span></a>
                                                    </div>
                                                </li>

                                               
                                            </ul>
                                    </div>
                                
                            </div>
                        </div>
                    </div>}
                </div>
               </div>
               <div className="modal-footer border-t border-solid border-unicon-modalBorder flex items-center justify-end gap-4 w-full h-20 rounded-b-xl absolute bottom-0 px-[30px]">
                    <button className="btn secondary-btn">
                        Cancel
                    </button>
                    <button className="btn primary-btn">
                        Save
                    </button>
                </div>
                </div>
               
            </SettingModal>
        </div>
    );
};

export default AdminTopHeader;
